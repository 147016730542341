import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginPageComponent} from './auth/components/login-page/login-page.component';
import {URL_PATHS} from './data/Enums';
import {DataPolicyComponent} from './auth/components/data-policy/data-policy.component';
import {EmailActionHandlerComponent} from './auth/components/email-action-handler/email-action-handler.component';
import {LoginResolveService} from './resolves/loginResolve/login-resolve.service';
import {IsLoggedInAuthGuardService} from './auth/authGuards/isLoggedIn/is-logged-in-auth-guard.service';
import {EmailLinkLandingComponent} from './auth/components/email-link-landing/email-link-landing.component';
import {Edge2xUsersPageComponent} from './components/modules/auth-config/edge2x-users-page/edge2x-users-page.component';
import {UserStatsPageComponent} from './components/modules/userStats/user-stats-page/user-stats-page.component';
import {OrganisationsPageComponent} from './components/modules/auth-config/organisations-page/organisations-page.component';


const routes: Routes = [
  {path: URL_PATHS.LOGIN, component: LoginPageComponent, pathMatch: 'full'},
  {path: URL_PATHS.DATA_POLICY, component: DataPolicyComponent},
  {path: URL_PATHS.EMAIL_ACTION_HANDLER, component: EmailActionHandlerComponent},
  {path: URL_PATHS.AUTH_CONFIG_PAGE, component: Edge2xUsersPageComponent, resolve: {data: LoginResolveService},   canActivate: [IsLoggedInAuthGuardService]},
  {path: URL_PATHS.USER_STATS_PAGE, component: UserStatsPageComponent, resolve: {data: LoginResolveService},   canActivate: [IsLoggedInAuthGuardService]},
  {path: URL_PATHS.USER_GROUPS_PAGE, component: OrganisationsPageComponent, resolve: {data: LoginResolveService},   canActivate: [IsLoggedInAuthGuardService]},
  {path: URL_PATHS.EMAIL_LINK_LANDING, component: EmailLinkLandingComponent},
  {path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

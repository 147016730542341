<div class="container-fluid authCardholder">
    <app-page-background-image></app-page-background-image>


    <mat-card class="authCard dataPolicyCard d-flex flex-column">
        <div style="padding: 45px" class="verticalFlex">
            <div class="position-relative " style="height: 60px">
                <img class="dataPolicyGridEdgeLogo" src="../../../../assets/gridedge_logos/gridedge.png"/>
                <div class="dataPolicyTitle">Data Usage Policy</div>
                <mat-icon class="closeButton primaryColor" [routerLink]="['']">close</mat-icon>
            </div>


            <markdown *ngIf="dataPolicyMarkdown != null"
                      [data]="dataPolicyMarkdown" class="mt-4 flex-1 dataPolicyHolder overflow-y-auto"></markdown>

            <div *ngIf="dataPolicyMarkdown == null" class="dataPolicyHolder">
                Loading...
            </div>
        </div>

    </mat-card>
</div>

import {Injectable} from '@angular/core';
import {URL_PATHS} from '../../data/Enums';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PageNavigatorService {

  constructor(private router: Router) {
  }

  loadLoginPage() {
    this.router.navigate([URL_PATHS.LOGIN]);
  }

  loadAuthConfigPage() {
    this.router.navigate([URL_PATHS.AUTH_CONFIG_PAGE]);
  }

  loadUserGroupsPage() {
    this.router.navigate([URL_PATHS.USER_GROUPS_PAGE]);
  }

  loadUserStatsPage() {
    this.router.navigate([URL_PATHS.USER_STATS_PAGE]);
  }


  /**
   * Gets the current page enum
   */
  getCurrentPage(): URL_PATHS {
    return this._mapURLToPageEnum(this.router.url);
  }


  /**
   * Map a url string to a page enum... This should be exactly what is seen in the browser
   * and should have nav/ if its a child of the nav
   * @param url - the string that you want to convert to a URL_PATH
   */
  _mapURLToPageEnum(url: string): URL_PATHS {
    // We must cut the first slash off and all parameters
    const urlWithoutFirstSlash = url.substr(1).split('?')[0];
    return urlWithoutFirstSlash as URL_PATHS;
  }
}

import {ChangeDetectorRef, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import {FormsModule} from '@angular/forms';
import {Edge2xUsersPageComponent} from './edge2x-users-page/edge2x-users-page.component';
import { OrganisationsPageComponent } from './organisations-page/organisations-page.component';
import {MatChipsModule} from '@angular/material/chips';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';



@NgModule({
  declarations: [Edge2xUsersPageComponent, OrganisationsPageComponent],
    imports: [
        CommonModule,
        MatIconModule,
        MatFormFieldModule,
        FormsModule,
        MatInputModule,
        MatSelectModule,
        MatChipsModule,
        MatButtonModule,
        MatCardModule,
    ]
})
export class AuthConfigModule { }

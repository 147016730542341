import {Component, Input, NgZone, OnDestroy, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Subscription} from 'rxjs';
import {AddOrModifyUserModalComponent} from '../../modals/add-or-modify-user-modal/add-or-modify-user-modal.component';
import {Edge2xUser} from '../../../../data/edge2xUser';
import {OrganisationSitesService} from '../../../../services/organisationSitesService/organisation-sites.service';
import {SnackBarService} from '../../../../services/snackBar/snack-bar.service';
import {DeleteUserModalComponent} from '../../modals/delete-user-modal/delete-user-modal.component';
import {Edge2xUsersService} from '../../../../services/Edge2xUsersService/edge2x-users.service';
import {UserStatsModalComponent} from '../../modals/user-stats-modal/user-stats-modal.component';
import {SiteConfig} from '../../../../data/SiteConfig';
import {LoadingService} from '../../../../services/loading/loading.service';


@Component({
    selector: 'app-edge2x-users-page',
    templateUrl: './edge2x-users-page.component.html',
    styleUrls: ['./edge2x-users-page.component.scss']
})
export class Edge2xUsersPageComponent implements OnInit, OnDestroy {
    snackbarOpenTime = 10000;
    usersList: Edge2xUser[] = [];
    filteredUsers: Edge2xUser[];
    filterText: string;

    edge2xUsersChangedSubscription: Subscription;

    selectedSort: SORT_ENUM = SORT_ENUM.EMAIL;


    addUserDialog: MatDialogRef<AddOrModifyUserModalComponent>;
    editUserDialog: MatDialogRef<AddOrModifyUserModalComponent>;
    deleteUserDialog: MatDialogRef<DeleteUserModalComponent>;

    userUIDToOrderedSites: Map<string, SiteConfig[]>;

    constructor(private loadingService: LoadingService, private snackBarService: SnackBarService,
                public edge2xUsersService: Edge2xUsersService, private dialog: MatDialog,
                public organisationSitesService: OrganisationSitesService) {
    }

    /**
     * This will update the filters users on load (so they are sorted correctly) and also create a
     * subscription that will also update filter users when the service changes.. e.g. a user is added or removed
     */
    ngOnInit() {
        this.edge2xUsersChangedSubscription = this.edge2xUsersService.users$.subscribe(newUsers => {
            console.log('New users received');
            this.buildUserUIDToOrderedSites();
            this.updateFilteredUsers();
        });
        this.updateFilteredUsers();
    }


    ngOnDestroy(): void {
        this.edge2xUsersChangedSubscription.unsubscribe();
    }

    /**
     * This will create a "ModifyUserModalComponent" dialog that can used to create a new Edge2xUser
     * The dialog will pass a user object back and if not null will call the firebase function to add the user
     * If successful they will be added to the user list in the edge2xUsersService
     */
    addEdge2xUser() {
        this.addUserDialog = this.dialog.open(AddOrModifyUserModalComponent, {
            height: "90%",
            width: "90%",
            data: {
                allSites: this.organisationSitesService.allSiteUUIDS,
                allUsers: this.edge2xUsersService.users,
                allOrganisations: this.organisationSitesService.allOrganisations,
                user: null
            }
        });
        this.addUserDialog.afterClosed().subscribe(async newUser => {
            try {
                if (newUser != null) {
                    this.loadingService.setLoading('Processing create user request');
                    await this.edge2xUsersService.addNewUser(newUser);
                    this.loadingService.hideLoading();
                    this.snackBarService.openSnackBar('Successfully created the user', null, this.snackbarOpenTime);
                }
            } catch (e) {
                console.log(e);
                this.loadingService.setErrorLoading();
                this.snackBarService.openSnackBar('Failed to create the user : ' + e, null, this.snackbarOpenTime);
            }
        });
    }

    /**
     * This will create a "ModifyUserModalComponent" dialog and prepopulate it with an existing users details
     * when updated it will pass the Edge2xUser object back and call the firebase function to edit a user
     * if successful the user in the list will be updated
     * @param userToEdit: Edge2xUser - the user we want to modify
     */
    editEdge2xUser(userToEdit: Edge2xUser) {
        this.editUserDialog = this.dialog.open(AddOrModifyUserModalComponent, {
            height: "90%",
            width: "90%",
            data: {
                allSites: this.organisationSitesService.allSiteUUIDS,
                allUsers: this.edge2xUsersService.users,
                allOrganisations: this.organisationSitesService.allOrganisations,
                user: userToEdit
            }
        });

        this.editUserDialog.afterClosed().subscribe(async newUser => {
            try {
                if (newUser != null) {
                    this.loadingService.setLoading('Processing edit user request');
                    await this.edge2xUsersService.replaceUser(newUser, userToEdit);
                    this.loadingService.hideLoading();
                    this.snackBarService.openSnackBar('Successfully updated the user', null, this.snackbarOpenTime);
                }
            } catch (e) {
                console.log(e);
                this.loadingService.setErrorLoading();
                this.snackBarService.openSnackBar('Failed to edit the user : ' + e, null, this.snackbarOpenTime);
            }
        });
    }

    /**
     * This will create a "DeleteUserModalComponent" that can be used to delete a user from Edge2x
     * If the user is returned from the dialog the delete user cloud function will be called
     * if successful they will be removed from the user list in the edge2xUsersService
     * @param userToDelete: Edge2xUser - the user we want to delete
     */
    deleteEdge2xUser(userToDelete: Edge2xUser) {
        this.deleteUserDialog = this.dialog.open(DeleteUserModalComponent, {
            maxHeight: 500,
            maxWidth: 600,
            data: {
                user: userToDelete
            }
        });
        this.deleteUserDialog.afterClosed().subscribe(async newUser => {
            try {
                if (newUser != null) {
                    this.loadingService.setLoading('Processing delete user request');
                    await this.edge2xUsersService.deleteUser(newUser);
                    this.loadingService.hideLoading();
                    this.snackBarService.openSnackBar('Successfully deleted the user', null, this.snackbarOpenTime);
                }
            } catch (e) {
                alert(e);
                console.log(e);
                this.loadingService.setErrorLoading();
                this.snackBarService.openSnackBar('Failed to delete the user : ' + e, null, this.snackbarOpenTime);
            }
        });
    }


    openLoginHistoryDialog(user: Edge2xUser) {
        this.dialog.open(UserStatsModalComponent, {
            maxHeight: 500,
            maxWidth: 600,
            minHeight: 500,
            minWidth: 600,
            data: {
                user
            }
        });
    }


    /**
     * This is used to update the list of filtered users. This will be called when the user enters a name/org filter in the
     * search OR when the user list is modified
     */
    updateFilteredUsers() {
        let newArray = [];
        if (this.edge2xUsersService.users != null) {
            if (this.filterText == null || this.filterText === '') {
                newArray = this.edge2xUsersService.users;
            } else {
                this.edge2xUsersService.users.forEach(user => {
                    if (user.organisation.includes(this.filterText) || user.email.includes(this.filterText) || user.uid.includes(this.filterText)) {
                        newArray.push(user);
                    }
                });
            }
        }

        if (newArray.length > 0) {
            newArray.sort((a: Edge2xUser, b: Edge2xUser) => {
                switch (this.selectedSort) {
                    case SORT_ENUM.EMAIL:
                        return a.email.toLowerCase() < b.email.toLowerCase() ? -1 : 1;
                    case SORT_ENUM.FIRSTNAME:
                        return a.userProfile.firstname.toLowerCase() < b.userProfile.firstname.toLowerCase() ? -1 : 1;
                    case SORT_ENUM.LASTNAME:
                        return a.userProfile.lastname.toLowerCase() < b.userProfile.lastname.toLowerCase() ? -1 : 1;
                    case SORT_ENUM.USER_GROUP:
                        return a.organisation.toLowerCase() < b.organisation.toLowerCase() ? -1 : 1;
                    case SORT_ENUM.UID:
                        return a.uid.toLowerCase() < b.uid.toLowerCase() ? -1 : 1;
                }
            });
        }

        this.filteredUsers = newArray;

    }

    /**
     * Builds the map of user id to ordered sites
     * This can be used to get a list of sites names in the html
     * It will update the userUIDToOrderedSites field when users change
     */
    buildUserUIDToOrderedSites() {
        const newMap: Map<string, SiteConfig[]> = new Map<string, SiteConfig[]>();

        if (this.edge2xUsersService.users != null) {
            for (let i = 0; i < this.edge2xUsersService.users.length; i++) {
                const siteConfigs: SiteConfig[] = [];
                const user = this.edge2xUsersService.users[i];
                for (let j = 0; j < user.sites.length; j++) {
                    const siteUUID = user.sites[j];
                    let siteConfig: SiteConfig = this.organisationSitesService.siteUUIDToConfigMap.get(siteUUID);
                    if (siteConfig == null) {
                        siteConfig = new SiteConfig(siteUUID, null, null, null);
                    }
                    siteConfigs.push(siteConfig);
                }
                siteConfigs.sort((a, b) => a.getDisplayNameOrUUIDIfNull().localeCompare(b.getDisplayNameOrUUIDIfNull()));
                newMap.set(user.uid, siteConfigs);
            }
        }
        this.userUIDToOrderedSites = newMap;
    }


    public get SORT_ENUM(): typeof SORT_ENUM {
        return SORT_ENUM;
    }
}


export enum SORT_ENUM {
    FIRSTNAME,
    LASTNAME,
    USER_GROUP,
    EMAIL,
    UID
}

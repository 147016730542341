export class Organisation {
  name: string;
  sites: Set<string> = new Set<string>();

  constructor(name: string, sites: Set<string>) {
    this.name = name;
    this.sites = sites;
  }

  // user this when passing into modal to ensure its pass by reference
  clone() {
    const sites: Set<string> = new Set();
    this.sites.forEach(value => {
      sites.add(value);
    });
    return new Organisation(this.name, sites);
  }
}

import {Component} from '@angular/core';
import {Subscription} from 'rxjs';
import {AuthService} from './auth/services/auth/auth.service';
import {PageNavigatorService} from './services/pageNavigatorService/page-navigator.service';
import {URL_PATHS} from './data/Enums';
import {environment} from '../environments/environment';
import firebase from 'firebase/compat';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'whitelist-admin';
    authChangeSubscription: Subscription;

    constructor(public authService: AuthService, public pageNavigatorService: PageNavigatorService) {
        this.authChangeSubscription = authService.authStateStream.subscribe(
            async (user: firebase.User) => {
                if (user) {
                    this.authService.isLoggingTheUserIn = true;
                    // refresh the token
                    const token = await user.getIdTokenResult(true);
                    console.log(token.claims);

                    if (user.tenantId !== environment.tenantId) {
                        this.logout();
                    } else {
                        await this.pageNavigatorService.loadAuthConfigPage();
                    }
                }
            });
    }


    logout() {
        this.authService.logout();
        this.pageNavigatorService.loadLoginPage();
    }

    /**
     * Determines whether to show the nav bar or not
     */
    shouldShowNavBar(): boolean {
        const currentPage = this.pageNavigatorService.getCurrentPage();
        switch (currentPage) {
            case URL_PATHS.LOGIN:
            case URL_PATHS.DATA_POLICY:
            case URL_PATHS.PASSWORD_RESET:
            case URL_PATHS.EMAIL_ACTION_HANDLER:
            case URL_PATHS.EMAIL_LINK_LANDING:
                return false;
            default:
                return true;
        }
    }
}

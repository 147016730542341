import {Component, OnInit} from '@angular/core';
import {Organisation} from '../../../../data/Organisation';
import {OrganisationSitesService} from '../../../../services/organisationSitesService/organisation-sites.service';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AddEditOrganisationModalComponent} from '../../modals/add-edit-organisation-modal/add-edit-organisation-modal.component';

@Component({
    selector: 'app-organisations-page',
    templateUrl: './organisations-page.component.html',
    styleUrls: ['./organisations-page.component.scss']
})
export class OrganisationsPageComponent implements OnInit {


    addEditOrgDialog: MatDialogRef<AddEditOrganisationModalComponent>;

    constructor(public organisationsSitesService: OrganisationSitesService, private dialog: MatDialog,) {
    }

    ngOnInit(): void {
    }

  /**
   * Add or edit an organisation. Organisations must have at least 1 site associated with them
   * @param organisationToEdit instance of Organisation
   */
  addNewOrganisation(organisationToEdit: Organisation) {
        this.addEditOrgDialog = this.dialog.open(AddEditOrganisationModalComponent, {
            height: '600px',
            width: '500px',
            data: {
                allSiteUUIDS: this.organisationsSitesService.allSiteUUIDS,
                organisation: organisationToEdit
            },
            autoFocus: false
        });
        this.addEditOrgDialog.afterClosed().subscribe((newOrg: Organisation) => {
          // This is the organisation described on the form that has been submitted before the dialog was closed
            if (newOrg != null) {
                if (organisationToEdit == null) {
                    this.organisationsSitesService.allOrganisations.push(newOrg);
                } else {
                    this.organisationsSitesService.updateOrganisation(organisationToEdit.name, newOrg);
                }
            }
        });
    }

}

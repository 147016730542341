import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {DatePickerService} from '../../../../services/datePickerService/date-picker.service';
import {TimeService} from '../../../../services/time/time.service';
import {DateRange, MatCalendar} from '@angular/material/datepicker';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import { Subscription} from 'rxjs';
import {GeneralService} from '../../../../services/generalService/general.service';
import {AvailableDates} from '../../../../data/AvailableDates';
import {TimeZoneEnum} from '../../../../data/Enums';
import {pairwise} from 'rxjs/operators';


@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
})
export class DateRangePickerComponent implements OnInit, AfterViewChecked, OnDestroy, OnChanges {

  @ViewChild('matCalendar') calendar: MatCalendar<Date>;


  @Input() selectedDateRange: DateRange<string> = null;
  @Output() selectedDateRangeChange = new EventEmitter();


  @Input() isCalendarOnly = true;
  /**
   * The available dates array should be provided in YYYY-MM-DD
   * format
   * e.g. ['2020-04-28', '2020-04-29' ... ]
   */
  @Input() availableDates: AvailableDates;
  @Input() sizeRangeSelection = null;

  hasDoneRequest = false;


  range: UntypedFormGroup;
  formSubscription: Subscription;
  hasClickedArrow = false;

  localDateRange: DateRange<Date>;

  constructor(public datePickerService: DatePickerService, private timeService: TimeService,
              private generalService: GeneralService, private formBuilder: UntypedFormBuilder, private cdRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.range = this.formBuilder.group({
      start: new UntypedFormControl((this.selectedDateRange && this.selectedDateRange.start) ? this.selectedDateRange.start : ''),
      end: new UntypedFormControl((this.selectedDateRange && this.selectedDateRange.end) ? this.selectedDateRange.end : ''),
    });

    this.formSubscription = this.range.valueChanges.pipe(pairwise()).subscribe(async ([prev, next]: [{ start: any, end: any }, { start: any, end: any }]) => {

      // stop the form patch value updating if there is an external change of selectedDateRange
      if (this.selectedDateRange == null || (next.start !== this.selectedDateRange.start && next.end !== this.selectedDateRange.end)) {
        const newStartDate = new Date(next.start).getTime();
        const newEndDate = new Date(next.end).getTime();

        if (!this.hasClickedArrow && next.end !== null && next.start !== null && next.end !== '' && next.start !== '' && prev.end !== next.end && newEndDate >= newStartDate && newEndDate <= new Date().getTime()) {
          this.localDateRange = new DateRange<Date>(new Date(next.start), new Date(next.end));
          this.fireNewLocalDateChange(this.localDateRange);
        }
      }
    });

    this.setLocalDateToMatchInput();

  }

  ngAfterViewChecked() {
    if (this.range.get('start').value !== '' && this.range.get('start').value !== null && (this.range.get('end').value === '' || this.range.get('end').value === null)) {

    } else {
      if (this.range.get('start').value === null) {
        this.range.get('end').patchValue(null);
      }
    }
    this.cdRef.detectChanges();
  }


  _onSelectedChange(date: Date): void {

    // They havent clicked one or they want to start fresh date range
    if (this.localDateRange == null || this.localDateRange.start != null && this.localDateRange.end != null) {
      this.localDateRange = new DateRange(date, null);
    } else {
      // The day they picked is before the start so make start the end
      if (date < this.localDateRange.start) {
        this.localDateRange = new DateRange(date, null);
      } else {
        // The second date they picked was after the first date so continue
        this.localDateRange = new DateRange(this.localDateRange.start, date);

        // if its over the max num range cap it
        const startMoment = this.timeService.jsDateToMoment(this.localDateRange.start, TimeZoneEnum.LOCAL);
        const endMoment = this.timeService.jsDateToMoment(this.localDateRange.end, TimeZoneEnum.LOCAL);
        if (this.sizeRangeSelection != null && Math.abs(endMoment.diff(startMoment, 'days')) > this.sizeRangeSelection) {
          this.localDateRange = new DateRange(this.localDateRange.start, startMoment.add(this.sizeRangeSelection, 'days').toDate());
        }

        // emit the change
        this.fireNewLocalDateChange(this.localDateRange);
      }

    }
  }


  fireNewLocalDateChange(newDateRange: DateRange<Date>) {
    const startDateString = this.timeService.jsDateToMoment(newDateRange.start, TimeZoneEnum.LOCAL).format('YYYY-MM-DD');
    const endDateString = this.timeService.jsDateToMoment(newDateRange.end, TimeZoneEnum.LOCAL).format('YYYY-MM-DD');
    this.selectedDateRange = new DateRange<string>(startDateString, endDateString);
    this.selectedDateRangeChange.emit(this.selectedDateRange);
  }


  /**
   * function object that is passed into the mat-calendar
   * calls the handler method so we can test login
   * @param date : JS date - The date that will be passed by the angular mat-calendar
   * @return bool - whether it is available.
   */
  filterDatesCaller = (date: Date): boolean => {
    return this.datePickerService.isDateAvailable(date, this.availableDates);
  }

  /**
   * Reset the values when leave the page and unsubscribe everything
   */
  public ngOnDestroy() {
    this.generalService.tryUnsubscribeObservable(this.formSubscription);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedDateRange != null && changes.selectedDateRange.previousValue !== changes.selectedDateRange.currentValue) {
      if (this.range != null) {
        this.range.get('start').patchValue(changes.selectedDateRange.currentValue.start);
        this.range.get('end').patchValue(changes.selectedDateRange.currentValue.end);
      }

      this.setLocalDateToMatchInput();
    }
  }


  setLocalDateToMatchInput() {
    if (this.selectedDateRange != null) {
      const startDateMoment = this.timeService.stringToMomentObject(this.selectedDateRange.start, 'YYYY-MM-DD', TimeZoneEnum.LOCAL);
      const endDateMoment = this.timeService.stringToMomentObject(this.selectedDateRange.end, 'YYYY-MM-DD', TimeZoneEnum.LOCAL);
      this.localDateRange = new DateRange<Date>(startDateMoment.toDate(), endDateMoment.toDate());
      if (this.calendar != null) {
        this.calendar.activeDate = this.localDateRange.start;
        this.calendar.updateTodaysDate();
      }
    }
  }
}



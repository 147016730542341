<div class="container-fluid authCardholder">
    <app-page-background-image></app-page-background-image>

    <mat-card class="authCard passwordResetBox text-center">
        <div class="container p-0 align-items-center verticalFlex h-100 w-100">
            <app-auth-card-header></app-auth-card-header>
            <div class="flex-1 holder verticalFlex justify-content-center">
                <div class="verticalFlex flex-1 justify-content-center align-items-center"
                     *ngIf="authService.isLoggingTheUserIn == false">

                    <!-- NOT SENDING EMAIL AND HASNT SENT -->
                    <form *ngIf="hasSentEmail == false && isSendingEmail == false" [formGroup]="loginForm" (ngSubmit)="callRecaptcha()"
                          autocomplete="off">
                        <mat-form-field floatLabel=never class="w-100 noPaddingTopFormInput"
                                        appearance="outline">
                            <input
                                id="email" matInput
                                placeholder='Email'
                                formControlName="email">
                            <mat-error id="invalid_email_error" class="error" *ngIf="email.invalid">
                                Please enter a valid email
                            </mat-error>
                        </mat-form-field>
                        <div class="text-center pt-2">
                            <mat-checkbox id="checkbox" formControlName="checkBox"
                                          color="primary"
                                          (change)="onDataPolicyChecked($event)">
                                <span class="fontWeight500">I have read Grid Edge's <a class="dataPolicyLink"
                                                                                       [routerLink]="['/' + URL_PATHS.DATA_POLICY]">data policy</a>  and agree to the terms and conditions</span>
                            </mat-checkbox>
                        </div>
                        <div class="text-center pt-2">
                            <button id="signInButton" mat-raised-button [disabled]="loginForm.invalid" type="submit"
                                    color="primary" class="row col-6 mt-1 mb-1 button">GENERATE LOGIN LINK
                            </button>
                        </div>
                        <div class="error" *ngIf="errorMessage !== ''">
                            <mat-error id="bad_credentials_error" class="marginCommonErrorMessage">
                                {{errorMessage}}
                            </mat-error>
                        </div>
                    </form>

                    <!-- HAS SENT EMAIL AND IS NOT SENDING -->
                    <div *ngIf="hasSentEmail == true && isSendingEmail == false">
                        <div class="body-2">An email has been sent to you which contains a link to login to your account</div>
                        <div class="pt-4 caption">Not received an email? Please click <a (click)="resetForm()"
                                                                                         class="cursorPointer hyperlink">here</a> to request another
                        </div>
                    </div>

                    <!-- IS SENDING EMAIL AND HAS NOT SENT -->
                    <div *ngIf="isSendingEmail == true && hasSentEmail == false"
                         class="d-flex justify-content-center flex-1 align-items-center">
                        <app-loading-spinner [isSmallText]="true" [loadingText]="'Generating login email...'"></app-loading-spinner>
                    </div>
                </div>


                <div *ngIf="authService.isLoggingTheUserIn == true"
                     class="d-flex justify-content-center flex-1 align-items-center">
                    <app-loading-spinner [isSmallText]="true" [loadingText]="generalService.LOGGING_USER_IN_LOADING_MESSAGE"></app-loading-spinner>
                </div>
            </div>
        </div>
    </mat-card>

    <re-captcha #captchaRef="reCaptcha" (error)="handleRecaptchaError()" (resolved)="submitForm($event)" [siteKey]="environment.recaptchaKey" size="invisible"></re-captcha>

</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {AddOrModifyUserModalComponent} from './add-or-modify-user-modal/add-or-modify-user-modal.component';
import {DeleteUserModalComponent} from './delete-user-modal/delete-user-modal.component';
import {BlockCopyPasteDirective} from '../../../directives/blockCopyPateDirective';
import { UserStatsModalComponent } from './user-stats-modal/user-stats-modal.component';
import {AppModule} from '../../../app.module';
import {UserStatsModule} from '../userStats/user-stats.module';
import { AddEditOrganisationModalComponent } from './add-edit-organisation-modal/add-edit-organisation-modal.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {AuthModule} from '../../../auth/auth.module';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {MatRadioModule} from '@angular/material/radio';


const modals = [
  AddOrModifyUserModalComponent,
  DeleteUserModalComponent,
    AddEditOrganisationModalComponent
];

@NgModule({
    declarations: [modals, BlockCopyPasteDirective, UserStatsModalComponent],
    imports: [
        CommonModule,
        FormsModule,
        MatSlideToggleModule,
        MatButtonModule,
        MatChipsModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        UserStatsModule,
        ReactiveFormsModule,
        MatCheckboxModule,
        AuthModule,
        ScrollingModule,
        MatRadioModule
    ]
})
export class ModalsModule { }

import {Injectable} from '@angular/core';

import {Organisation} from '../../data/Organisation';
import {SiteConfig} from '../../data/SiteConfig';

@Injectable({
    providedIn: 'root'
})
export class OrganisationSitesService {

    ORGANISATIONS_TO_SITE_DB_PATH = 'authConfig/organisationsToSiteUUIDs';
    LOGIN_EVENTS_DB_PATH = 'userTracking/logins';

    siteUUIDToConfigMap: Map<string, SiteConfig>;

    allOrganisations: Organisation[] = null;
    allSiteUUIDS: Set<string> = null;


    constructor() {
    }

    /**
     * Give the sitesToOrganisation firebase node this will create Site objects
     * A Set or organisation names is also created for convenience
     * @param sitesToOrganisations :{
     *   "Org": ["site1", "site2"]
     *   ...
     * }
     *
     */
    loadSitesAndOrganisations(organisationToSite: {}, allSiteUUIDS: string[]) {
        const allOrganisations: Organisation[] = [];
        for (const org in organisationToSite) {
            const sites = new Set<string>(organisationToSite[org]);
            allOrganisations.push(new Organisation(
                org,
                sites
            ));
        }
        this.allOrganisations = allOrganisations.sort((a: Organisation, b: Organisation) => {
            return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        });
        this.allSiteUUIDS = new Set<string>(allSiteUUIDS);
    }

    /**
     * This will get a sites display name if provided an site id
     * if the display name cannot be matched then null will be returned
     * @param siteUID : string - the id of the site
     */
    getSiteDisplayName(siteUID: string): string {
        if (this.siteUUIDToConfigMap == null || this.siteUUIDToConfigMap.get(siteUID) == null) {
            return siteUID;
        } else {
            const name = this.siteUUIDToConfigMap.get(siteUID).displayName;
            return name != null ? name : siteUID;
        }
    }

    /**
     * Order an array of site UUIDs by their corresponding display names
     * @param sites
     */
    getSitesUUIDsOrderedByName(sites: string[]): {
        uuid: string,
        displayName: string,
        organisationName: string
    }[] {
        const returnObj: {
            uuid: string,
            displayName: string,
            organisationName: string
        }[] = [];
        for (let i = 0; i < sites.length; i++) {
            returnObj.push({
                uuid: sites[i],
                displayName: this.getSiteDisplayName(sites[i]),
                organisationName: this.getOrgNameForSite(sites[i]),
            });
        }

        returnObj.sort((a: {
            uuid: string,
            displayName: string
        }, b: {
            uuid: string,
            displayName: string
        }) => a.displayName.localeCompare(b.displayName));
        return returnObj;
    }

    /**
     * Used to update an organisation with a new one
     * @param oldOrgName: string - the name of the org we are updating
     * @param newParam: Organisation - the new object that will replace it
     */
    updateOrganisation(oldOrgName: string, newParam: Organisation) {
        for (let i = 0; i < this.allOrganisations.length; i++) {
            if (this.allOrganisations[i].name === oldOrgName) {
                this.allOrganisations[i] = newParam;
                break;
            }
        }
    }

    getOrgNameForSite(siteUID: string): string {
        if (this.siteUUIDToConfigMap == null || this.siteUUIDToConfigMap.get(siteUID) == null) {
            return siteUID;
        } else {
            const name = this.siteUUIDToConfigMap.get(siteUID).organisationName;
            return name != null ? name : 'Unknown';
        }
    }
}

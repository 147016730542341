<div>
  <h3>Delete User</h3>

  <div class="italic">Are you sure you want to delete {{userToDelete.email}}?</div>

  <mat-form-field class="pt-2 w-100" appearance="fill">
    <mat-label>Type DELETE to continue with the deletion</mat-label>
    <input matInput [(ngModel)]="deleteCheckString">
  </mat-form-field>



  <div class="pt-2 d-flex justify-content-center align-items-center">
    <button mat-flat-button color="warn" (click)="close()">Cancel</button>
    <div class="px-2"></div>
    <button mat-flat-button color="primary" [disabled]="canSubmit() == false" (click)="submit()">Submit</button>
  </div>
</div>

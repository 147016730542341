import {Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {GeneralService} from '../../../services/generalService/general.service';
import {Router} from '@angular/router';
import {URL_PATHS} from '../../../data/Enums';
import firebase from 'firebase/compat/app';
import ActionCodeSettings = firebase.auth.ActionCodeSettings;
import {Observable} from 'rxjs';

// @ts-ignore
@Injectable({
    providedIn: 'root'
})
export class AuthService {
    public authStateStream: any;
    isLoggingTheUserIn = false;

    emailRegex: RegExp = new RegExp('^[\\w-]+(\\.[\\w-]+)*@([a-z0-9-]+(\\.[a-z0-9-]+)*?\\.[a-z]{2,10}|(\\d{1,3}\\.){3}\\d{1,3})(:\\d{4})?$');


    constructor( private angularFireAuth: AngularFireAuth, private router: Router) {
        this.authStateStream = angularFireAuth.authState;
    }


    isSignInLink(url: string) {
        return this.angularFireAuth.isSignInWithEmailLink(url);
    }

    async signInWithEmailLink(email: string, link: string) {
        await this.angularFireAuth.signInWithEmailLink(email, link);
    }


    async sendEmailSignInLink(email: string) {
        const actionCodeSettings: ActionCodeSettings = {
            url: window.location.origin + '/' + URL_PATHS.EMAIL_LINK_LANDING,
            handleCodeInApp: true
        };
        await this.angularFireAuth.sendSignInLinkToEmail(email, actionCodeSettings);
    }

    /**
     * Checks if the userProfile is logged in
     * @return: boolean - whether they are logged in
     */
    async isLoggedIn(): Promise<boolean> {
        const currentUser = await this.angularFireAuth.currentUser;
        if (currentUser == null) {
            return false;
        } else {
            return true;
        }
    }

    getCurrentUser(): Promise<firebase.User> {
        return this.angularFireAuth.currentUser;
    }

    async getCurrentUserUid(): Promise<string> {
        const currentUser = await this.getCurrentUser();
        return currentUser.uid;
    }

    /**
     * Logs the userProfile out and navigates to login page
     */
    logout() {
        this.angularFireAuth.signOut().then(r => {
            console.log('signed out');
            // get a circular dependency if we use PageNavigatorService here, so use router directly
            // to go back to main passwordless login page. If we do this here password login is still available
            this.router.navigate(['']);
        });
    }


    /**
     * This will send a password reset email to the userProfile
     * @param email: String - the email to send the password reset to
     */
    async sendPasswordReset(email: string) {
        return await this.angularFireAuth.sendPasswordResetEmail(email).then(res => {
            return res;
        }, error => {
            return error;
        });
    }

    /**
     * Will check a provided action code
     * errors codes are as followed
     * - auth/expired-action-code - Thrown if the action code has expired
     * - auth/invalid-action-code - Thrown if the action code is invalid. This can happen if the code is malformed or has already been used.
     * - auth/userProfile-disabled - Thrown if the userProfile corresponding to the given action code has been disabled
     * - auth/userProfile-not-found - Thrown if there is no userProfile corresponding to the action code.
     *                          This may have happened if the userProfile was deleted between when the action code was issued
     *                          and when this method was called
     * @param code: string - the string to check
     * @returns Returns metadata about the code.
     */
    async checkActionCode(code: string) {
        return await this.angularFireAuth.checkActionCode(code);
    }

    /**
     * Will apply a provided action code
     * You can check if its valid before hand by calling
     * checkActionCode()
     * @param code: string - The code to apply
     */
    async applyActionCode(code: string) {
        return await this.angularFireAuth.applyActionCode(code);
    }


    /**
     * This will Confirm a password reset and change the users password
     * @param code: string - the token code that was supplied in the link that was sent to their email
     * @param newPassword: string - the new password they are changing to
     */
    async comfirmPasswordReset(code: string, newPassword: string) {
        return await this.angularFireAuth.confirmPasswordReset(code, newPassword);
    }


    /**
     * Log the user into the website
     * @param email: string - the email they typed in
     * @param password: string - password they typed in
     */
    loginWithPassword(email, password) {
        return this.angularFireAuth.signInWithEmailAndPassword(email, password);
    }


}



// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  version: '1.0.0',
  mock: false,
  tenantId: 'superAdmins-z0a7w',
  recaptchaKey: '6LfE3HUUAAAAALcRNOrsOHFmGmYF63NrDkHB8gsZ',
  firebase: {
    apiKey: 'AIzaSyBqzGO_Qp1krI5Sw57zOLq7UmDFgqnri9c',
    authDomain: 'development-170812.firebaseapp.com',
    databaseURL: 'https://development-170812.firebaseio.com',
    projectId: 'development-170812',
    storageBucket: 'development-170812.appspot.com',
    messagingSenderId: '520814393574'
  },


  api_urls: {
    SITE_LIST: 'https://api.gridedge.dev/v2/site/list',
    SITE_INFO: 'https://api.gridedge.dev/v2/site/info',
  },
};


import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {TimeService} from '../../../../services/time/time.service';
import {MatCalendar, MatCalendarCellCssClasses} from '@angular/material/datepicker';
import {NativeDateAdapter} from '@angular/material/core';
import {DatePickerService} from '../../../../services/datePickerService/date-picker.service';
import {TimeZoneEnum} from '../../../../data/Enums';
import {AvailableDates} from '../../../../data/AvailableDates';

/** Adapts the native JS Date for use with cdk-based components that work with dates. */
export class CustomDateAdapter extends NativeDateAdapter {
    parse(value: any): Date | null {
        return super.parse(value);
    }

    /**
     * Sets it to start on monday instead of sunday
     */
    getFirstDayOfWeek(): number {
        return 1;
    }
}

@Component({
    selector: 'app-date-picker',
    templateUrl: './date-picker.component.html',
    styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent implements OnInit, OnChanges {

    @ViewChild('matCalendar') calendar: MatCalendar<Date>;


    /**
     * The selected date should be provided in YYYY-MM-DD format
     */
    @Input() selectedDate;
    @Output() selectedDateChange = new EventEmitter();

    @Input() isSelectFutureDate = true;

    /**
     * The available dates array should be provided in YYYY-MM-DD
     * format
     * e.g. ['2020-04-28', '2020-04-29' ... ]
     */
    @Input() availableDates: AvailableDates;


    todayDate = new Date();

    @Input() isViewingWeek: boolean;


    constructor(public timeService: TimeService, public datePickerService: DatePickerService) {
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.isViewingWeek != null && changes.isViewingWeek.previousValue !== changes.isViewingWeek.currentValue) {
            this.calendar.updateTodaysDate();
        }
        if (changes.selectedDate != null && changes.selectedDate.previousValue !== changes.selectedDate.currentValue) {
            console.log('The datepicker selected date changed');
            this.calendar.activeDate = this.getStringDateAsDateObject();
            this.calendar.updateTodaysDate();
        }
    }

    /**
     * Converts the selected date string to a JS date based on the users timezone
     * that can be used by the mat calendar
     */
    getStringDateAsDateObject() {
        const jsDate = this.timeService.stringToMomentObject(this.selectedDate, 'YYYY-MM-DD', TimeZoneEnum.LOCAL).toDate();
        return jsDate;
    }

    /**
     * Called when the userProfile selects a new date
     * This will turn the selected date to a string based on the users timezone
     * It will also emit the change to accomplish 2-way binding.
     * @param newDate - Js Date - the new date they clicked on in the calendar
     */
    dateChanged(newDate) {
        this.selectedDate = this.timeService.timeStampToString(newDate, 'YYYY-MM-DD', TimeZoneEnum.LOCAL);
        this.selectedDateChange.emit(this.selectedDate);
        this.calendar.updateTodaysDate();
    }

    /**
     * function object that is passed into the mat-calendar
     * calls the handler method so we can test login
     * @param date : JS date - The date that will be passed by the angular mat-calendar
     * @return bool - whether it is available.
     */
    filterDatesCaller = (date: Date): boolean => {
        return this.isDateAvailable(date);
    }

    /**
     * Actual handler so we can test the logic
     * to determine which dates to make selectable (based on the availableDates input)
     * if null is passed as an input none of the dates will be selectable.
     * @param date : JS date - The date to check
     * @return bool - whether it is available.
     */
    isDateAvailable(date: Date): boolean {
        if (this.availableDates !== undefined && this.availableDates !== null) {
            const d = this.timeService.jsDateToMoment(date, TimeZoneEnum.LOCAL).format('YYYY-MM-DD');
            return this.availableDates.days.includes(d);
        } else {
            return false;
        }
    }


    /**
     * Return the current date if the input of selecting dates in the future is false
     */
    getCurrentTime() {
        if (!this.isSelectFutureDate) {
            return this.todayDate;
        } else {
            return null;
        }
    }

    /**
     * Used to assign custom classes to the datepicker
     */
    dateClass() {
        return (date: Date): MatCalendarCellCssClasses => {
            if (this.selectedDate == null || this.isViewingWeek == null || this.isViewingWeek === false) {
                return '';
            } else {
                const d = this.timeService.jsDateToMoment(date, TimeZoneEnum.LOCAL);
                const selectedDate = this.timeService.stringToMomentObject(this.selectedDate, 'YYYY-MM-DD', TimeZoneEnum.LOCAL);
                return d.isSame(selectedDate, 'isoWeek') ? 'week-toggle-day' : '';
            }
        };
    }

}




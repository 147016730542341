import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {LoadingSpinnerComponent} from './components/loading-spinner/loading-spinner.component';
import {LoginPageComponent} from './components/login-page/login-page.component';
import {PageBackgroundImageComponent} from './components/page-background-image/page-background-image.component';
import {AuthCardHeaderComponent} from './components/auth-card-header/auth-card-header.component';
import {DataPolicyComponent} from './components/data-policy/data-policy.component';
import {EmailActionHandlerComponent} from './components/email-action-handler/email-action-handler.component';
import {MatCardModule} from '@angular/material/card';
import {ReactiveFormsModule} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import {MarkdownModule} from 'ngx-markdown';
import {MatTooltipModule} from '@angular/material/tooltip';
import {RecaptchaModule} from 'ng-recaptcha';
import {RouterModule} from '@angular/router';
import { EmailLinkLandingComponent } from './components/email-link-landing/email-link-landing.component';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {AngularFireFunctions, REGION} from '@angular/fire/compat/functions';
import { LoginPasswordComponent } from './components/login-password/login-password.component';

@NgModule({
    declarations: [LoadingSpinnerComponent, LoginPageComponent, PageBackgroundImageComponent, AuthCardHeaderComponent,  DataPolicyComponent, EmailActionHandlerComponent,  EmailLinkLandingComponent, LoginPasswordComponent],
    imports: [
        MatCardModule,
        CommonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatCheckboxModule,
        MatInputModule,
        MarkdownModule.forRoot(),
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatRippleModule,
        MatTooltipModule,
        RecaptchaModule,
        RouterModule,
    ],

    providers: [
        {provide: REGION, useValue: 'europe-west1'},
        AngularFireFunctions, AngularFireStorage
    ],

    exports: [LoadingSpinnerComponent, LoginPageComponent, PageBackgroundImageComponent, AuthCardHeaderComponent, DataPolicyComponent, EmailActionHandlerComponent]
})
export class AuthModule { }

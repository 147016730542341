import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {GeneralService} from '../../../services/generalService/general.service';
import {AuthService} from '../../services/auth/auth.service';
import {SnackBarService} from '../../../services/snackBar/snack-bar.service';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {PageNavigatorService} from '../../../services/pageNavigatorService/page-navigator.service';

@Component({
    selector: 'app-email-link-landing',
    templateUrl: './email-link-landing.component.html',
    styleUrls: ['./email-link-landing.component.scss']
})
export class EmailLinkLandingComponent implements OnInit {

    email: UntypedFormControl;
    htmlErrorMessage: string;
    showSendAnotherEmail = false;
    emailPromptForm: UntypedFormGroup;
    showEmailPromptForm: boolean;


    constructor(public snackBarService: SnackBarService, public generalService: GeneralService, public pageNavigatorService: PageNavigatorService, public authService: AuthService, private activatedRoute: ActivatedRoute) {
        this.authService.isLoggingTheUserIn = false;
        this.showEmailPromptForm = false;
    }

    ngOnInit() {
        this.email = new UntypedFormControl('', [Validators.email, Validators.pattern(this.authService.emailRegex.source), Validators.required]);

        this.emailPromptForm = new UntypedFormGroup({
            email: this.email,
        });

        if (this.authService.isSignInLink(window.location.href)) {
            // Try and get the user's email from local storage, so we don't have to ask them for it again
            // This could happen if e.g. they requested the link from a different browser or machine, or if they have arrived here after the email was
            // removed from storage after successful login earlier
            const localStorageEmail = window.localStorage.getItem('emailForSignIn');
            if (this.generalService.passesEmailRegex(localStorageEmail)) {
                this.loginWithLink(localStorageEmail);
            } else {
                // We need to prompt for the email
                this.showEmailPromptForm = true;
            }
        } else {
            this.pageNavigatorService.loadLoginPage();
        }
    }


    /**
     * Tries to login with the login link
     * @param email: string - the email associated with the login link
     */
    loginWithLink(email: string) {
        console.log('Email is ' + email);
        this.showEmailPromptForm = false;

        this.authService.isLoggingTheUserIn = true;
        if (!this.generalService.passesEmailRegex(email)) {
            this.pageNavigatorService.loadLoginPage();
        } else {
            this.authService.signInWithEmailLink(email, window.location.href)
                .then((result) => {
                    window.localStorage.removeItem('emailForSignIn');
                })
                .catch((error) => {
                    this.authService.isLoggingTheUserIn = false;

                    if (error.code === 'auth/internal-error') {
                        this.htmlErrorMessage = 'You are not authorised to use Edge2x.\nPlease contact your administrator.';
                    } else if (error.code === 'auth/invalid-action-code') {
                        this.htmlErrorMessage = 'The email link has expired or has already been used';
                        this.showSendAnotherEmail = true;
                    } else {
                        this.htmlErrorMessage = 'The provided email login link is invalid';
                        this.showSendAnotherEmail = true;
                    }
                });
        }
    }

}

<div class="verticalFlex">


  <div class="d-flex align-items-center pt-4">
    <div class="px-4 py-2 ">
      <div class="page-title">All User Login History</div>
      <div class="loginUsageExplanationText">{{generalService.USER_LOGIN_EVENT_EXPLANATION_TEXT}}</div>
    </div>
    <div class="flex-1"></div>
    <app-date-range-picker class="px-4" [isCalendarOnly]="false"
                           [selectedDateRange]="selectedDateRange"
                           [availableDates]="availableDates"
                           [sizeRangeSelection]="null"
                           (selectedDateRangeChange)="handleNewDatesPicked($event)"></app-date-range-picker>
  </div>


  <div class="flex-1 verticalFlex p-4">
    <div class="flex-1 overflow-y-auto">
      <mat-table [dataSource]="dataSource" class=" mat-elevation-z8 w-100">

        <!-- uid Column -->
        <ng-container matColumnDef="uid">
          <mat-header-cell *matHeaderCellDef>UID</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.uid}} </mat-cell>
        </ng-container>


        <!-- Email Column -->
        <ng-container matColumnDef="email">
          <mat-header-cell *matHeaderCellDef>Email</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.email}} </mat-cell>
        </ng-container>

        <!-- Date Column -->
        <ng-container matColumnDef="date">
          <mat-header-cell *matHeaderCellDef>Date</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{getFormattedDate(element.date)}} </mat-cell>
        </ng-container>

        <!-- Time Column -->
        <ng-container matColumnDef="time">
          <mat-header-cell *matHeaderCellDef>Time</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.time}} </mat-cell>
        </ng-container>


        <ng-container matColumnDef="noRecords">
          <mat-cell class="boldFont" *matFooterCellDef style="text-align: center;justify-content: center">
            No records found
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>


        <mat-footer-row [hidden]="dataSource != null && dataSource.data != null && dataSource.data.length > 0"
                        *matFooterRowDef="['noRecords']"
                        colspan="2"></mat-footer-row>

      </mat-table>
    </div>

  </div>
</div>

import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {WHITELABEL_OPERATOR, Edge2xUser, UserProfile, UserRoleEnum} from '../../../../data/edge2xUser';
import {AuthService} from '../../../../auth/services/auth/auth.service';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {Organisation} from '../../../../data/Organisation';
import {OrganisationSitesService} from '../../../../services/organisationSitesService/organisation-sites.service';
import {
    AbstractControlOptions,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import {GeneralService} from '../../../../services/generalService/general.service';



@Component({
    selector: 'app-add-or-modify-user-modal',
    templateUrl: './add-or-modify-user-modal.component.html',
    styleUrls: ['./add-or-modify-user-modal.component.scss']
})
export class AddOrModifyUserModalComponent implements OnInit {


    originalUser: Edge2xUser = null;
    allOrganisations: Set<Organisation>;
    allSites: Set<string>;


    selectedSites: Set<string> = new Set();
    allUsers: Edge2xUser[];

    addEditForm: UntypedFormGroup;

    operatorOptions: WHITELABEL_OPERATOR[] = [
        WHITELABEL_OPERATOR.GRIDEDGE,
        WHITELABEL_OPERATOR.ZEMPOWER,
    ];

    readonly MAX_NAME_LENGTH = 75;

    UserAlreadyExists = false;

    siteOptions: {
        uuid: string,
        displayName: string
    }[] = null;

    constructor(@Inject(MAT_DIALOG_DATA) data, private dialogRef: MatDialogRef<AddOrModifyUserModalComponent>,
                private authService: AuthService, public organisationSitesService: OrganisationSitesService, private fb: UntypedFormBuilder,
                private generalService: GeneralService) {
        this.allSites = data.allSites;
        this.allUsers = data.allUsers;
        this.allOrganisations = data.allOrganisations;
        this.originalUser = data.user;
    }


    ngOnInit() {
        if (this.originalUser != null) {

            this.addEditForm = this.fb.group({
                    firstname: new UntypedFormControl(this.originalUser.userProfile.firstname, [Validators.required, Validators.minLength(0), Validators.maxLength(75)]),
                    lastname: new UntypedFormControl(this.originalUser.userProfile.lastname, [Validators.required, Validators.minLength(0), Validators.maxLength(75)]),
                    email: new UntypedFormControl(this.originalUser.email, [Validators.required, Validators.email, Validators.pattern(this.authService.emailRegex)]),
                    confirmationEmail: new UntypedFormControl(this.originalUser.email, [Validators.required, Validators.email, Validators.pattern(this.authService.emailRegex)]),
                    isAdmin: new UntypedFormControl(this.originalUser.role === UserRoleEnum.ADMIN, Validators.required),
                    organisation: new UntypedFormControl(this.originalUser.organisation, Validators.required),
                    operator: new UntypedFormControl(this.originalUser.operator, Validators.required),
                }, {
                    validators: [
                        this.generalService.matchInputs('email', 'confirmationEmail'),
                        this.checkGridedgeOrgAndEmail()
                    ]
                } as AbstractControlOptions
            );

            // Make sure edit requests add additional sites if we add them to the org
            if (this.addEditForm.get('isAdmin').value === true) {
                this.selectedSites = this.getListOfSitesForSelectedOrganisation();
            } else {
                this.selectedSites = new Set(this.originalUser.sites);
            }
        } else {
            this.addEditForm = this.fb.group({
                    firstname: new UntypedFormControl('', [Validators.required, Validators.minLength(0), Validators.maxLength(75)]),
                    lastname: new UntypedFormControl('', [Validators.required, Validators.minLength(0), Validators.maxLength(75)]),
                    email: new UntypedFormControl('', [Validators.required, Validators.pattern(this.authService.emailRegex)]),
                    confirmationEmail: new UntypedFormControl('', [Validators.required, Validators.pattern(this.authService.emailRegex)]),
                    isAdmin: new UntypedFormControl(false, Validators.required),
                    organisation: new UntypedFormControl(null, Validators.required),
                    operator: new UntypedFormControl(null, Validators.required),
                }, {
                    validators: [
                        this.generalService.matchInputs('email', 'confirmationEmail'),
                        this.checkGridedgeOrgAndEmail()
                    ]
                } as AbstractControlOptions
            );
        }


        // set the intial site options
        this.siteOptions = this.getListOfSitesForSelectedOrganisationDisplayVersion();
    }

    /**
     * Will return an organisation object given a name
     * nothing is returned if no match is found
     * @param name: string - the name of the organisation
     */
    getOrganisationFromName(name: string): Organisation {
        for (const org of this.allOrganisations.values()) {
            if (org.name === name) {
                return org;
            }
        }
    }

    /**
     * Handles when the user clicks on the role slider
     * If the new value is admin and an org is already selected
     * then they will be set to have all the sites in the respective org toggled
     * Otherwise the selected sites is initialised to be an empty Set
     * @param matSlideToggleChange: MatSlideToggleChange - the new toggle event
     */
    handleRoleClick(matSlideToggleChange: MatSlideToggleChange) {
        if (this.addEditForm.get('organisation').value != null && matSlideToggleChange.checked === true) {
            this.selectedSites = this.getListOfSitesForSelectedOrganisation();
        } else {
            this.selectedSites = new Set<string>();
        }
    }

    /**
     * Handles when the user clicks an organisation
     * If the role that has been selected is ADMIN then all sites in the respective organisation will be assigned to the user
     * Otherwise a new Set will be initialised
     * Clicking the same organisation will un-toggle the "selected organisation"  and as such will clear the selected sites
     * @param org: string - the organisation name that was clicked
     */
    handleOrgClick(org: string) {
        // set the new org and clear their selected sites
        if (this.addEditForm.get('organisation').value === org) {
            this.addEditForm.get('organisation').patchValue(null);

            this.siteOptions = null;
            this.selectedSites = new Set<string>();
        } else {
            // IF they are admin set all sites to be selected
            this.addEditForm.get('organisation').patchValue(org);
            this.siteOptions = this.getListOfSitesForSelectedOrganisationDisplayVersion();
            if (this.addEditForm.get('isAdmin').value === true) {
                this.selectedSites = this.getListOfSitesForSelectedOrganisation();
            } else {
                this.selectedSites = new Set<string>();
            }
        }
    }

    /**
     * Handles when the user clicks on a site chip
     * This method only applies to basic users
     * If the site is not already selected it will be added to the set otherwise it will be removed
     * @param site: string - the name of the site that was clicked
     */
    handleSiteClick(site: string) {
        if (this.addEditForm.get('isAdmin').value === false) {
            if (this.selectedSites.has(site)) {
                this.selectedSites.delete(site);
            } else {
                this.selectedSites.add(site);
            }
        }
    }

    /**
     * Determines whether the dialog can be submitted
     */
    isSubmitButtonDisabled(): boolean {
        console.log('Checking if disabled');

        if (this.addEditForm.invalid) {
            return true;
        }

        // email not in use by someone else
        if (this.checkIfEmailAlreadyInUse()) {
            return true;
        }

        // has an org that exists
        const selectedOrg = this.getOrganisationFromName(this.addEditForm.get('organisation').value);
        if (selectedOrg == null) {
            return true;
        }

        // has at least one selected
        if (this.selectedSites == null || this.selectedSites.size === 0) {
            return true;
        }

        // Check a site that doesnt exist hasn't shown up
        for (const site of this.selectedSites) {
            if (selectedOrg.sites.has(site) === false) {
                return true;
            }
        }

        if (this.checkSitesBelongToOrganisation(this.selectedSites, this.addEditForm.get('organisation').value) === false) {
            return true;
        }

        // Check if admint they have all the sites
        if (this.addEditForm.get('isAdmin').value === true) {
            const allSites: Set<string> = this.getListOfSitesForSelectedOrganisation();
            if (this.checkTwoSetsAreEqual(allSites, this.selectedSites) === false) {
                return true;
            }
        }

        return false;
    }

    /**
     * Determines whether two sets contain the same things
     * @param allSites: Set<string> - first set of sites
     * @param selectedSiteNames: Set<string> - second set of sites
     */
    checkTwoSetsAreEqual(allSites: Set<string>, selectedSiteNames: Set<string>): boolean {
        if (allSites.size !== selectedSiteNames.size) {
            return false;
        }
        for (const a of allSites) {
            if (!selectedSiteNames.has(a)) {
                return false;
            }
        }
        return true;
    }

    /**
     * Checks whether a list of sites belong to a given organisation
     * @param selectedSites: Set<string> - set of site names
     * @param organisation: string - the name of the organisation
     */
    checkSitesBelongToOrganisation(selectedSites: Set<string>, organisation: string) {
        const orgObject: Organisation = this.getOrganisationFromName(organisation);
        if (orgObject == null) {
            return false;
        }
        for (const site in selectedSites.values()) {
            if (orgObject.sites.has(site) === false) {
                return false;
            }
        }
        return true;
    }

    /**
     * Gets the list of sites for a given organisation
     */
    getListOfSitesForSelectedOrganisation(): Set<string> {
        const organisation = this.getOrganisationFromName(this.addEditForm.get('organisation').value);
        if (organisation) {
            return organisation.sites;
        } else {
            return null;
        }
    }


    /**
     * Gets the list of sites for a given organisation
     */
    getListOfSitesForSelectedOrganisationDisplayVersion(): {
        uuid: string,
        displayName: string
    }[] {
        const sites: Set<string> = this.getListOfSitesForSelectedOrganisation();
        if (sites != null) {
            return this.organisationSitesService.getSitesUUIDsOrderedByName(Array.from(sites));
        }
        return null;
    }


    /**
     * Submits the dialog if allowed
     * This will also return the new/modified user
     * The dialog will be closed
     */
    submit() {

        if (!this.isSubmitButtonDisabled()) {
            const role = this.addEditForm.get('isAdmin').value === true ? UserRoleEnum.ADMIN : UserRoleEnum.BASIC;
            const userProfile: UserProfile = new UserProfile(this.addEditForm.get('firstname').value, this.addEditForm.get('lastname').value);
            // create a new Edge2xUser instance

            const loginEvents = this.originalUser == null ? null : this.originalUser.loginEvents;
            const uid = this.originalUser == null ? null : this.originalUser.uid;

            const operatorName = this.addEditForm.get('operator').value;
            const newUser: Edge2xUser = new Edge2xUser(this.addEditForm.get('organisation').value, this.addEditForm.get('email').value, role, Array.from(this.selectedSites.values()), userProfile, loginEvents, uid, operatorName);
            this.dialogRef.close(newUser);
        }
    }

    public get WHITELABEL_OPERATOR(): typeof WHITELABEL_OPERATOR {
        return WHITELABEL_OPERATOR;
    }

    /**
     * Closes the dialog and passes null as a response
     */
    close() {
        this.dialogRef.close(null);
    }

    /**
     * callback that checks if the email is already in use by another user
     * This will assign the boolean
     */
    emailChangeCallback() {
        this.UserAlreadyExists = this.checkIfEmailAlreadyInUse();
    }

    /**
     * Checks if the email is already in use by another user
     */
    checkIfEmailAlreadyInUse(): boolean {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.allUsers.length; i++) {
            if (this.addEditForm.get('email').value === this.allUsers[i].email && (this.originalUser == null || this.addEditForm.get('email').value !== this.originalUser.email)) {
                return true;
            }
        }

        return false;
    }


    checkGridedgeOrgAndEmail() {
        return (formGroup: UntypedFormGroup) => {
            const email = formGroup.controls['email'].value;
            const regex = new RegExp(/@gridedge.co.uk\s*$/);
            if (formGroup.controls['organisation'].value?.includes('gridedge')) {
                if (regex.test(email) === false) {
                    formGroup.controls['email'].setErrors({gridedge_email_required: true});
                    return;
                }
            }
            formGroup.controls['email'].setErrors(null);
        };
    }

}

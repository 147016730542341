import {Injectable} from '@angular/core';
import {AuthService} from '../../services/auth/auth.service';
import {CanActivate, Router} from '@angular/router';
import {URL_PATHS} from '../../../data/Enums';

@Injectable({
    providedIn: 'root'
})
export class IsLoggedInAuthGuardService implements CanActivate {

    constructor(private authService: AuthService, private router: Router) {
    }

    /**
     * Used to prevent users from accessing routed components when not logged in
     */
    async canActivate() {
        const isLoggedIn = await this.authService.isLoggedIn();
        if (isLoggedIn) {
            return true;
        }

        this.router.navigate([URL_PATHS.LOGIN]).then(() => {
            console.log('Redirecting to login as not logged in');
        });
        return false;
    }
}

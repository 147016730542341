<div style="text-align: center">
    <div class="d-flex justify-content-center">
        <div *ngIf="error">
            <img src="../../../../assets/svgIcons/sad_robot.svg">
        </div>
        <div *ngIf="!error" [ngClass]="{'white': hasBackground}" class="la-ball-grid-pulse la-3x">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
    <div *ngIf="!error" class=" pb-1 operatingMessage" style="padding-top: 20px"
         [ngClass]="{'primaryColor': !hasBackground , 'whiteFontColor': hasBackground, 'headline-6': !isSmallText, 'subtitle-1': isSmallText }">
        {{loadingText == null ? this.FETCHING_DATA : loadingText}}
    </div>
    <div *ngIf="error" class=" pb-1 operatingMessage" style="padding-top: 20px"
         [ngClass]="{'primaryColor': !hasBackground, 'whiteFontColor': hasBackground, 'headline-6': !isSmallText, 'subtitle-1': isSmallText }">
        {{ERROR_FETCHING_DATA}}
    </div>
    <div *ngIf="buttonName != null" [ngStyle]="{'padding-top': !hasBackground ? '20%' : ''}">
        <button [ngClass]="{'cancelButtonFormat': !hasBackground, 'cancelButtonFormatWithBackground': hasBackground}"
                mat-button class="w-100"
                (click)="emmitClick()">{{buttonName}}</button>
    </div>
</div>

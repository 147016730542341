<div *ngIf="loadingService.isShown" [ngClass]="loadingService.isError == false
        ? 'fullPageBackgroundLoading'
        : 'fullPageBackgroundError'" id="loadingSpinnerHolder">
    <div *ngIf="loadingService.isError == true" class="centered" style="align-items: center">


        <app-loading-spinner [error]="loadingService.isError"
                             [hasBackground]="true" buttonName="Close"
                             (clickedButton)="loadingService.hideLoading()"></app-loading-spinner>
    </div>
    <div *ngIf="loadingService.isError == false" class="centered" style="align-items: center">

        <app-loading-spinner [error]="loadingService.isError"
                             [hasBackground]="true" buttonName="Cancel"
                             (clickedButton)="loadingService.cancelLoading()"></app-loading-spinner>
    </div>
</div>

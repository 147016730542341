import {StaticFunctions} from './StaticFunctions';

export class AvailableDates {
    timestamps: number[];
    days: string[];

    constructor(timestamps: number[], days: string[]) {
        this.timestamps = timestamps;
        this.days = days;
    }

    static fromJSON(arrayOfJson, timeZone): AvailableDates {
        console.log(arrayOfJson);

        const timestampArray: number[] = [];
        const daysArray: string[] = [];
        for (let i = 0; i < arrayOfJson.length; i++) {
            const timestamp = arrayOfJson[i].ActiveDay;
            timestampArray.push(timestamp);
            const stringDate = StaticFunctions.convertTimestampToTimeZonedMoment(timestamp, timeZone).format('YYYY-MM-DD');
            daysArray.push(stringDate);
        }
        return new AvailableDates(timestampArray, daysArray);
    }
}

<div class="verticalFlex">
  <div class="flex-1">
    <mat-table  [dataSource]="dataSource" class=" mat-elevation-z8 w-100" style="overflow: auto">

      <!-- Date Column -->
      <ng-container matColumnDef="date">
        <mat-header-cell  *matHeaderCellDef>Date</mat-header-cell>
        <mat-cell  *matCellDef="let element"> {{getFormattedDate(element.date)}} </mat-cell>
      </ng-container>

      <!-- Time Column -->
      <ng-container matColumnDef="time">
        <mat-header-cell  *matHeaderCellDef>Time</mat-header-cell>
        <mat-cell  *matCellDef="let element"> {{element.time}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="noRecords">
        <mat-cell class="boldFont" *matFooterCellDef style="text-align: center;justify-content: center">
          No records found
        </mat-cell>
      </ng-container>



      <mat-header-row  *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row  *matRowDef="let row; columns: displayedColumns;"></mat-row>

      <mat-footer-row [hidden]="dataSource != null && dataSource.data != null && dataSource.data.length > 0"
                      *matFooterRowDef="['noRecords']"
                      colspan="2"></mat-footer-row>

    </mat-table>
  </div>
</div>

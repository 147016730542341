import {Injectable} from '@angular/core';
import {from, Observable, Observer} from 'rxjs';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {AngularFireFunctions} from '@angular/fire/compat/functions';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {AngularFireDatabase} from '@angular/fire/compat/database';
import firebase from 'firebase/compat';
import DataSnapshot = firebase.database.DataSnapshot;
import {AngularFirestore} from '@angular/fire/compat/firestore';

@Injectable({
    providedIn: 'root'
})
export class FirebaseService {

    // tslint:disable-next-line:max-line-length
    constructor(private firebaseAuth: AngularFireAuth, private functions: AngularFireFunctions, private storage: AngularFireStorage,
                private realtimeDatabase: AngularFireDatabase, private firestore: AngularFirestore) {
    }

    /**
     * Sets value of a database node at the specified path. If a Site object param is provided, use that site's database. If not use default project database
     * @param site optional Site object
     * @param nodePath path on database to use
     * @param data new value to set path to
     */
    async setDatabaseNode(nodePath: string, data, dbUrl?: string) {

        if (dbUrl != null) {
            await this.realtimeDatabase.database.app.database(dbUrl).ref(nodePath).set(data);
        } else {
            await this.realtimeDatabase.database.ref(nodePath).set(data);
        }
    }

    /**
     * Call firebase function
     * @param functionName: FIREBASE_FUNCTIONS - enum of function to call
     * @param params: jsObj - the params that will be supplied to the function
     * @return promise : Promise that will contain the data
     */
    async callFirebaseFunction(functionName: FIREBASE_FUNCTIONS, params) {
        const functionNameString = this.getFunctionNameFromEnum(functionName);
        const call = this.functions.httpsCallable(functionNameString,  {timeout: 540000});
        return await call(params).toPromise();
    }

    /**
     * Gets the string name for a cloud function
     * @param enumParam: FIREBASE_FUNCTIONS - the enum of the function you want the string for
     */
    getFunctionNameFromEnum(enumParam: FIREBASE_FUNCTIONS): string {
        switch (enumParam) {
            case FIREBASE_FUNCTIONS.SEND_LOGIN_LINK_EMAIL:
                return 'sendLoginLinkEmail'
            case FIREBASE_FUNCTIONS.CHECK_RECAPTCHA_CALLABLE:
                return 'checkRecaptchaCallable';
            case FIREBASE_FUNCTIONS.CREATE_USER_FROM_SUPER_ADMIN:
                return 'createUserFromSuperAdmin';
            case FIREBASE_FUNCTIONS.UPDATE_USER_FROM_SUPER_ADMIN:
                return 'updateUserFromSuperAdmin';
            case FIREBASE_FUNCTIONS.DELETE_USER_FROM_SUPER_ADMIN:
                return 'deleteUserFromSuperAdmin';
            case FIREBASE_FUNCTIONS.GET_ALL_USERS_IN_USER_TENANT:
                return 'getAllUsersInUserTenantForSuperAdmin';
        }
        return null;
    }


    /**
     * Reads a node on firebase ONCE. Data is returned if exists otherwise null
     * If the site to query param is not supplied then the default database will be used
     * @param nodePath: string - The path to the node on firebase
     * @param siteNameToQuery: string - the site name to use. This should be the correctly formatted site name.. e.g. Grand Central
     */
    async readFirebaseNode(nodePath: string, dbUrl?: string) {
        let snapshot: DataSnapshot;
        if (dbUrl != null) {
            snapshot = await this.realtimeDatabase.database.app.database(dbUrl).ref(nodePath).once('value');
        } else {
            snapshot = await this.realtimeDatabase.database.ref(nodePath).once('value');
        }
        if (snapshot.exists()) {
            return snapshot.val();
        } else {
            return null;
        }
    }

    /**
     * Will return a map of doc id to doc data
     * @param nodePath
     */
    async readFirestoreCollection(nodePath: string) {
        try {
            const collection = await this.firestore.collection(nodePath).get().toPromise();
            const returnData = [];
            if (collection.docs) {
                for (let i = 0; i < collection.docs.length; i++) {
                    const doc = collection.docs[i];
                    if (doc && doc.data()) {
                        returnData[doc.id] = doc.data();
                    }
                }
            }
            return returnData;
        } catch (e) {
            console.log(e);
            console.log('Failed to read path');
            console.log(nodePath);
        }
        return null;
    }

    async readFirestoreNode(nodePath: string): Promise<any> {
        try {
            const doc = await this.firestore.doc(nodePath).get().toPromise();
            if (doc.exists) {
                return doc.data();
            }
        } catch (e) {
            console.log(e);
            console.log('Failed to read path');
            console.log(nodePath);
        }
        return null;
    }

    async setFirestoreNode(nodePath: string, newDate: {}) {
        await this.firestore.doc(nodePath).set(newDate);
    }

    async pushAutoGenNodeFirestore(collectionPath: string, data: {}): Promise<string> {
        const pushreq = await this.firestore.collection(collectionPath).add(data);
        return pushreq.id;
    }

    async deleteFirestoreNode(nodePath: string) {
        await this.firestore.doc(nodePath).delete();
    }

    async updateFirestoreNode(nodePath: string, newDate: {}) {
        try {
            await this.firestore.doc(nodePath).update(newDate);
        } catch (e) {
            console.log(e);
        }
    }

    /**
     * Gets a download link to a file on firebase storage
     * @param path: string - A path to the file on firebase storeage
     */
    async getDownloadLink(path: string) {
        const downloadLink: string = await this.storage.ref(path).getDownloadURL().toPromise();
        return downloadLink;
    }

    /**
     * Returns an observable to a location on firebase.
     * If the site to query param is not supplied then the default database will be used
     * @param path: string - A path to the node on firebase
     * @param site: string - the site name to use. This should be the correctly formatted site name.. e.g. Grand Central
     * @return Observable - triggers when the node on firebase changes
     */
    getLiveUpdatesForFirebaseNode(path: string, dbUrl?: string) {
        if (dbUrl != null) {
            return new Observable((observer: Observer<DataSnapshot>) => {
                this.realtimeDatabase.database.app.database(dbUrl).ref(path).on('value', (data) => {
                    observer.next(data);
                });
            });
        } else {
            return new Observable((observer: Observer<DataSnapshot>) => {
                this.realtimeDatabase.database.ref(path).on('value', (data) => {
                    observer.next(data);
                });
            });
        }
    }

    /**
     * Give a blob file will create a webkit object
     * @param file: blob - file to convert to webkit object
     */
    createObjectURL(file) {
        if ((window as any).webkitURL) {
            return (window as any).webkitURL.createObjectURL(file);
        } else if (window.URL && window.URL.createObjectURL) {
            return window.URL.createObjectURL(file);
        } else {
            return null;
        }
    }

    /**
     * Reads a file from cloud storage and creates a webkit object
     * @param path : string - A path to the file on firebase storeage
     * @return Promise<any> - A promise to the webkit object
     */
    async readFileFromFirebaseCloudStorage(path: string, fileType: XMLHttpRequestResponseType): Promise<any> {

        return new Promise(async (resolve, reject) => {
            try {
                const xhr = new XMLHttpRequest();
                xhr.responseType = fileType;
                xhr.onload = (event) => {
                    resolve(xhr.response);
                };
                xhr.open('GET', await this.getDownloadLink(path));
                xhr.send();
            } catch (e) {
                reject(e);
            }
        });
    }

    /**
     * Pushes an extra item to a node
     * @param path path on database
     * @param json the item to push
     * @param site sitename - need this to look up database
     */
    async pushOnFirebaseDatabaseNode(path: string, json: {}, dbUrl?: string) {
        let pushedNode = null;
        if (dbUrl == null) {
            pushedNode = await this.realtimeDatabase.database.ref(path).push(json);
        } else {
            pushedNode = await this.realtimeDatabase.database.app.database(dbUrl).ref(path).push(json);
        }
        return pushedNode;
    }

    /**
     * Removes node at path of database for specified Site
     * @param path path on database to remove
     * @param site Site whose database to use
     */
    async removeNodeOnFirebaseDatabase(path: string, dbUrl?: string) {
        if (dbUrl == null) {
            await this.realtimeDatabase.database.ref(path).remove();
        } else {
            await this.realtimeDatabase.database.app.database(dbUrl).ref(path).remove();
        }
    }

    /**
     *
     * @param json: {} - json to upload
     * @param path: string - the path for the file to go on storage
     * @param fileNameAndExtension: string - the file name and extension, should really always be json.. e.g. "data.json"
     */
    async uploadBlobFileToFirebaseStorage(json, path: string, fileNameAndExtension: string) {
        const dataBlob = new Blob([json], {
            type: 'application/json'
        });
        const upload = this.storage.upload(path + '/' + fileNameAndExtension, dataBlob);
        return from(upload);
    }

    /**
     * Call the firebase function to delete the file in storage
     */
    deleteObjectFromStorage(path: string) {
        return this.storage.ref(path).delete().toPromise();
    }

    async getMetaDataFromStorageFile(path: string) {
        let metaData;
        try {
            metaData = await this.storage.ref(path).getMetadata().toPromise();
        } catch (e) {
            throw e;
        }
        return metaData;
    }
}


export enum FIREBASE_FUNCTIONS {
    CHECK_RECAPTCHA_CALLABLE = 'CHECK_RECAPTCHA_CALLABLE',
    CREATE_USER_FROM_SUPER_ADMIN = 'CREATE_USER_FROM_SUPER_ADMIN',
    SEND_LOGIN_LINK_EMAIL = 'SEND_LOGIN_LINK_EMAIL',
    UPDATE_USER_FROM_SUPER_ADMIN = 'UPDATE_USER_FROM_SUPER_ADMIN',
    DELETE_USER_FROM_SUPER_ADMIN = 'DELETE_USER_FROM_SUPER_ADMIN',
    GET_ALL_USERS_IN_USER_TENANT = 'GET_ALL_USERS_IN_USER_TENANT',
}

export enum FIRESTORE_COLLECTION_NAME {
    USER_SITE_ACCESS_CLAIMS = 'USER_SITE_ACCESS_CLAIMS'
}

import {Injectable} from '@angular/core';
import * as moment from 'moment';
import 'moment-timezone';
import {TimeZoneEnum} from '../../data/Enums';

@Injectable({
  providedIn: 'root'
})
export class TimeService {

  constructor() {
  }


  /**
   * Currently used to convert a timestamp to string
   * @param timestamp: number - millisecond epoch
   * @param format string - The format you want e.g. 'YYYY-MM-DD'
   * @param timeZoneEnum: TimeZoneEnum - timezone you want it in.
   * @param site - optionalParam: Site - if you required a specific sites timezone
   *                                 If unset will use sitService.selectedSite timezone
   *                                 for this to applied TimeZone enum must be site
   */
  timeStampToString(timestamp: number, format: string, timeZoneEnum: TimeZoneEnum) {
    switch (timeZoneEnum) {
      case TimeZoneEnum.UTC:
        return moment.utc(timestamp).format(format);

      case TimeZoneEnum.LOCAL:
        return moment(timestamp).local().format(format);
    }
  }


  /**
   * Gets a moment objects from a JS Date
   * // If UTC is required it is expected that the provided JS DATE is already UTC
   * @param date: JSDate - the js date you want to convert
   * @param timeZoneEnum : TimeZoneEnum - The timezone enum to use
   * @param site - optionalParam: Site - if you required a specific sites timezone
   *                                 If unset will use sitService.selectedSite timezone
   *                                 for this to applied TimeZone enum must be site
   */
  jsDateToMoment(date, timeZoneEnum: TimeZoneEnum) {
    switch (timeZoneEnum) {
      case TimeZoneEnum.UTC:
        return moment(date).utc();

      case TimeZoneEnum.LOCAL:
        return moment(date).local();
    }
  }


  /**
   * Convers a string to a moment object
   * @param dateString : String - The date you want to convert
   * @param format: String - The format that the date is in e.g. "YYYY-MM-DD"
   * @param timeZoneEnum : TimeZoneEnum - The timezone for the date
   * @param site - optionalParam: Site - if you required a specific sites timezone
   *                                 If unset will use sitService.selectedSite timezone
   *                                 for this to applied TimeZone enum must be site
   */
  stringToMomentObject(dateString: string, format: string, timeZoneEnum: TimeZoneEnum) {
    switch (timeZoneEnum) {
      case TimeZoneEnum.UTC:
        return moment.utc(dateString, format);

      case TimeZoneEnum.LOCAL:
        return moment(dateString, format).local();
    }
  }


  /**
   * Converts a timestamp to a moment object
   * Must be in Millseconds
   * @param timetamp : number - Millisecond timestamp
   * @param timeZoneEnum : TimeZoneEnum - The timezone for the date
   * @param site - optionalParam: Site - if you required a specific sites timezone
   *                                 If unset will use sitService.selectedSite timezone
   *                                 for this to applied TimeZone enum must be site
   */
  timestampToMomentObject(timetamp: number, timeZoneEnum: TimeZoneEnum) {
    switch (timeZoneEnum) {
      case TimeZoneEnum.UTC:
        return moment.utc(timetamp);

      case TimeZoneEnum.LOCAL:
        return moment(timetamp).local();
    }
  }


  /**
   * Gets the current time as a moment object
   * @param timeZoneEnum: TimeZoneEnum - The timezone for the date
   * @param site - optionalParam: Site - if you required a specific sites timezone
   *                                 If unset will use sitService.selectedSite timezone
   *                                 for this to applied TimeZone enum must be site
   */
  getCurrentTimeMomentObject(timeZoneEnum: TimeZoneEnum) {
    switch (timeZoneEnum) {
      case TimeZoneEnum.UTC:
        return moment().utc();

      case TimeZoneEnum.LOCAL:
        return moment().local();
    }
  }

  /**
   * A using timestamp is in seconds rather than milliseconds so we must multiply it by 1000
   * before converting
   */
  unixTimestampToMomentObject(timetamp: number, timeZoneEnum: TimeZoneEnum) {

    switch (timeZoneEnum) {
      case TimeZoneEnum.UTC:
        return moment(timetamp * 1000).utc();
      case TimeZoneEnum.LOCAL:
        return moment(timetamp * 1000).local();
    }
  }

  /**
   * Transform Zulu date into new date with the wanted format format
   * @param zuluDate - string in zulu format
   */
  zuluTimeIntoDate(zuluDate: string, format: string) {
    return moment(zuluDate).utcOffset(zuluDate).format(format);
  }


  /**
   * Rounds a moment object to the nearest minute interval
   * e.g. 14:37 with 15 minute interval will become 14:30
   *      14:38 with 15 minute interval will become 14:45
   * @param dateMomentObject: Momnent object - the moment object to convert
   * @param duration: int - the minute interval to round
   */
  roundMinuteToInterval(dateMomentObject, duration) {
    const ROUNDING = duration * 60 * 1000; /*ms*/
    const rounded = moment(Math.round((+dateMomentObject) / ROUNDING) * ROUNDING);
    return rounded;
  }

}



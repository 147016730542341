import {Injectable} from '@angular/core';
import {DateRange} from '@angular/material/datepicker';
import {BehaviorSubject, Subscription} from 'rxjs';
import {TimeService} from '../time/time.service';
import {TimeZoneEnum} from '../../data/Enums';
import {AvailableDates} from '../../data/AvailableDates';

@Injectable({
    providedIn: 'root'
})
export class DatePickerService {

    numberOfDaysDelta: number;
    selectDateRangeBehaviorSubject: BehaviorSubject<DateRange<Date>> = new BehaviorSubject<DateRange<Date>>(new DateRange(null, null));
    selectDateRangeSubscription: Subscription;
    dateRange: DateRange<Date>;
    initialTimestampFromMapPage = null;

    constructor(private timeService: TimeService) {
        this.selectDateRangeBehaviorSubject.subscribe((dateRange: DateRange<Date>) => {
            this.dateRange = dateRange;
        });
    }


    /**
     * Actual handler so we can test the logic
     * to determine which dates to make selectable (based on the availableDates input)
     * if null is passed as an input none of the dates will be selectable.
     * @param date : JS date - The date to check
     * @param availableDates
     * @return bool - whether it is available.
     */
    isDateAvailable(date: Date, availableDates: AvailableDates): boolean {
        if (availableDates !== undefined && availableDates !== null) {
            const d = this.timeService.jsDateToMoment(date, TimeZoneEnum.LOCAL).format('YYYY-MM-DD');
            return availableDates.days.includes(d);
        } else {
            return false;
        }
    }

    /**
     * Function that will create the new DateRange object according to the boolean
     * @param isBefore: boolean - Parameter that will determine if we are going forward or backward in the calendar for
     * the new DateRange object
     */
    setNewDateRangeWithDelta(isBefore: boolean) {
        let selectedStartDate: Date;
        let selectedEndDate: Date;
        let newStartDate: Date;
        let newEndDate: Date;
        if (this.initialTimestampFromMapPage) {
            this.dateRange = new DateRange<Date>(new Date(this.initialTimestampFromMapPage - (24 * 60 * 60 * 1000)), new Date(this.initialTimestampFromMapPage));
            selectedStartDate = this.dateRange.start;
            selectedEndDate = this.dateRange.end;
            this.numberOfDaysDelta = 2;
            this.initialTimestampFromMapPage = null;
            newStartDate = selectedStartDate;
            newEndDate = selectedEndDate;
        } else {
            selectedStartDate = this.dateRange.start;
            selectedEndDate = this.dateRange.end;
            // If before is true:  we remove the value of delta that is between the days to the current start day
            // and we just remove 1 to the end date
            // ********
            // If false:  add one day from the end date and assign the value to the new start date and we add the delta value to the current end date
            // to be the new end date
            if (isBefore) {
                newStartDate = new Date(selectedStartDate.getTime() - (24 * 60 * 60 * 1000) * this.numberOfDaysDelta);
                newEndDate = new Date(selectedStartDate.getTime() - (24 * 60 * 60 * 1000));
            } else {
                newStartDate = new Date(selectedEndDate.getTime() + (24 * 60 * 60 * 1000));
                for (let i = 1; i <= this.numberOfDaysDelta; i++) {
                    const newDate = new Date(selectedEndDate.getTime() + (24 * 60 * 60 * 1000) * i);
                    if (newDate.getTime() < new Date().getTime()) {
                        newEndDate = newDate;
                    }
                }
            }
        }
        this.selectDateRangeBehaviorSubject.next(new DateRange<Date>(newStartDate, newEndDate));
    }
}

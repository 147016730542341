import {
  DateRange,
  MAT_DATE_RANGE_SELECTION_STRATEGY,
  MatDateRangeSelectionStrategy
} from '@angular/material/datepicker';
import {Directive, Inject, Injectable, Input} from '@angular/core';
import {DateAdapter} from '@angular/material/core';

@Injectable()
export class MaxRangeSelectionStrategy<D>
  implements MatDateRangeSelectionStrategy<D> {
  start: any;
  public delta: number;

  constructor(private _dateAdapter: DateAdapter<D>) {
  }

  selectionFinished(date: D, currentRange: DateRange<D>) {
    let {start, end} = currentRange;
    if (start == null || (start && end)) {
      start = date;
      end = null;
    } else if (end == null) {
      let maxDate;
      if (this.delta != null) {
        maxDate = this._dateAdapter.addCalendarDays(start, this.delta);
      }
      end = date && maxDate ? (date > maxDate ? maxDate : date) : date;
    }

    return new DateRange<D>(start, end);
  }

  createPreview(
    activeDate: D | null,
    currentRange: DateRange<D>
  ): DateRange<D> {
    if (currentRange.start && !currentRange.end) {
      let maxDate;
      if (this.delta != null) {
        maxDate = this._dateAdapter.addCalendarDays(
          currentRange.start,
          this.delta
        );
      }
      const rangeEnd = activeDate && maxDate ? activeDate > maxDate
          ? maxDate
          : activeDate
        : activeDate;

      return new DateRange(currentRange.start, rangeEnd);
    }

    return new DateRange<D>(null, null);
  }
}

@Directive({
  selector: '[maxRange]',
  providers: [
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: MaxRangeSelectionStrategy
    }
  ]
})
export class MaxRangeDirective {
  constructor(
    @Inject(MAT_DATE_RANGE_SELECTION_STRATEGY)
    private maxRangeStrategy: MaxRangeSelectionStrategy<any>
  ) {
  }

  @Input() set maxRange(value: number) {
    this.maxRangeStrategy.delta = +value || null;
  }
}

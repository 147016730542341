import {Component, OnInit} from '@angular/core';
import { URL_PATHS } from 'src/app/data/Enums';
import {FirebaseService} from '../../services/firebase/firebase.service';

@Component({
    selector: 'app-data-policy',
    templateUrl: './data-policy.component.html',
    styleUrls: ['./data-policy.component.scss']
})
export class DataPolicyComponent implements OnInit {
    dataPolicyMarkdown;


    constructor(public firebaseService: FirebaseService) {
    }

    ngOnInit() {
        this.firebaseService.readFileFromFirebaseCloudStorage('GDPR/gdpr_file.md', 'text').then((data) => {
            this.dataPolicyMarkdown = data;
        });
    }

    public get URL_PATHS(): typeof URL_PATHS {
      return URL_PATHS;
    }
}

import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class BackgroundLoaderService {

    _actualPaths: string[] = [
        'assets/background_images/01.jpg',
        'assets/background_images/02.jpg',
        'assets/background_images/03.jpg',
        'assets/background_images/04.jpg',
        'assets/background_images/05.jpg',
        'assets/background_images/06.jpg',
        'assets/background_images/07.jpg',
        'assets/background_images/08.jpg',
        'assets/background_images/09.jpg',
        'assets/background_images/10.jpg',
        'assets/background_images/11.jpg',
    ];

    constructor() {
    }


    getRandomImage() {
        const rnd = Math.floor(Math.random() * this._actualPaths.length);
        return this._actualPaths[rnd];
    }
}

<form class="flex-1 verticalFlex pb-3" [formGroup]="addEditForm">
    <div class="w-100 flex-1 verticalFlex">
        <h3 *ngIf="originalUser == null">Add New User</h3>
        <h3 *ngIf="originalUser != null">Edit User</h3>

        <mat-form-field class="pt-2 w-100" appearance="fill">
            <mat-label>First Name</mat-label>
            <input matInput formControlName="firstname" [maxLength]="MAX_NAME_LENGTH">
            <mat-error *ngIf="addEditForm.controls['firstname'].errors?.required">
                Please enter a first name
            </mat-error>
        </mat-form-field>

        <mat-form-field class="pt-1 w-100" appearance="fill">
            <mat-label>Last Name</mat-label>
            <input matInput formControlName="lastname" [maxLength]="MAX_NAME_LENGTH">
            <mat-error *ngIf="addEditForm.controls['lastname'].errors?.required">
                Please enter a last name
            </mat-error>
        </mat-form-field>

        <mat-form-field class="pt-1 w-100" appearance="fill">
            <mat-label>Email</mat-label>
            <input matInput formControlName="email" (ngModelChange)="emailChangeCallback()">
            <mat-error *ngIf="addEditForm.controls['email'].errors?.required">
                Please enter a valid email address
            </mat-error>
            <mat-error *ngIf="addEditForm.controls['email'].errors?.gridedge_email_required">
                A gridedge.co.uk email is required to add users to the gridedge user group
            </mat-error>
        </mat-form-field>

        <mat-form-field class="pt-1 w-100" appearance="fill">
            <mat-label>Confirmation email</mat-label>
            <input matInput formControlName="confirmationEmail" appBlockCopyPaste>
            <mat-error
                    *ngIf="addEditForm.get('confirmationEmail').errors?.NotEqual">
                Emails do not match
            </mat-error>
        </mat-form-field>


        <div class="errorText" *ngIf="UserAlreadyExists == true">A user with this email already exists</div>

        <div class="pt-3">
            <mat-slide-toggle formControlName="isAdmin" [labelPosition]="'before'" (change)="handleRoleClick($event)">
                Will this
                user
                be a site admin?
            </mat-slide-toggle>

            <div>Please note a site admin will automatically have access to all sites in the user group.</div>
        </div>

        <div class="pt-3">
            <div>Whitelabel Operator</div>
            <mat-radio-group formControlName="operator" class="d-flex" style="gap:10px">
                <mat-radio-button [value]="WHITELABEL_OPERATOR.GRIDEDGE">GridEdge</mat-radio-button>
                <mat-radio-button [value]="WHITELABEL_OPERATOR.ZEMPOWER">ZeMpower</mat-radio-button>
            </mat-radio-group>
        </div>

        <h4 class="pt-3">User Group</h4>
        <div style="max-height: 300px" class="overflow-y-auto">
            <div class="d-flex">
                <mat-chip-list class="p-2 h-100 overflow-y-auto">
                    <mat-chip *ngFor="let org of allOrganisations" class="cursorPointer"
                              (click)="handleOrgClick(org.name)"
                              [disableRipple]="true"
                              [ngClass]="{'selectedChip' : addEditForm.get('organisation').value == org.name, 'unSelectedChip' : addEditForm.get('organisation').value != org.name}">
                        {{org.name}}
                        <mat-icon
                                class="ml-2 addIcon"
                                [ngClass]="{'selectedTick' : addEditForm.get('organisation').value == org.name}">{{addEditForm.get('organisation').value == org.name ? "check_box" : "check_box_outline_blank"}}</mat-icon>
                    </mat-chip>
                </mat-chip-list>
            </div>
        </div>

        <h4 class="pt-3" *ngIf="addEditForm.get('organisation').value != null">Sites</h4>
        <div style="max-height: 350px" class="overflow-y-auto">
            <div class="d-flex" *ngIf="addEditForm.get('organisation').value != null">
                <mat-chip-list class="p-2">
                    <mat-chip *ngFor="let site of siteOptions"
                              (click)="handleSiteClick(site.uuid)"
                              [ngClass]="{'selectedChip':  selectedSites.has(site.uuid) == true, 'unSelectedChip': selectedSites.has(site.uuid) == false, 'selectedDisabledChip': addEditForm && addEditForm.controls['isAdmin'] && addEditForm.controls['isAdmin'].value == true}"
                              [disableRipple]="true">
                        {{site.displayName}}
                        <mat-icon
                                class="ml-2 addIcon">{{selectedSites.has(site.uuid) ? "remove_circle_outline" : "add_circle_outline"}}</mat-icon>
                    </mat-chip>
                </mat-chip-list>
            </div>
        </div>
        <div class="pt-3 d-flex justify-content-center align-items-center">
            <button mat-flat-button color="warn" (click)="close()">Cancel</button>
            <div class="px-2"></div>
            <button id="submitButton" mat-flat-button color="primary"
                    [disabled]="isSubmitButtonDisabled() || addEditForm.invalid" (click)="submit()">Submit
            </button>
        </div>
    </div>
</form>

import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {from, Observable, of} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {AuthService} from '../../auth/services/auth/auth.service';
import {DataMarshallerEndPoints, RequestType} from '../../data/Enums';

@Injectable({
    providedIn: 'root'
})
export class GridEdgeApiRequestService {


    constructor(private http: HttpClient, private authService: AuthService) {
    }

    /**
     * Makes a request to the gridedge api
     * Automatically handles the authorisation headers
     * @param endpoint: DataMarshallerEndPoints - The endpoint you  want to call
     * @param params: HttpParams - Http params with key value strings of data you want to send
     *                              VALUES MUST BE STRINGS... NO JSON so convert to strings beforehand
     * @returns The observable you can subscribe to to get the response.
     */
    makeRequest(endpoint: DataMarshallerEndPoints, params: HttpParams): Observable<HttpResponse<object>> {
        const type: RequestType = this._getRequestTypeForGridEdgeApiRequestEndpoint(endpoint);
        const url: string = this._getURLForApi(endpoint);

        return from(this._getAPIAuthHeaders()).pipe(
            switchMap((headers: HttpHeaders) => {
                // We pipe the errors so they do not crash fork joins
                // we can still check the request status in the response e.g. if its 500
                if (type === RequestType.GET) {
                    return this.http.get(url, {
                        headers,
                        params,
                        observe: 'response'
                    }).pipe(catchError(error => of(error)));
                } else {
                    // headers.append('content-type', 'application/x-www-form-urlencoded');
                    return this.http.post(url, params, {
                        headers,
                        observe: 'response'
                    }).pipe(catchError(error => of(error)));
                }
            }));
    }


    /**
     * Builds the Auth headers that are used to called the api
     * This will use the firebase userProfile id token
     * @return : HttpHeaders - The http header object with a bearer token
     */
    async _getAPIAuthHeaders(): Promise<HttpHeaders> {
        const currentUser = await this.authService.getCurrentUser();
        const token = await currentUser.getIdToken();
        console.log('Token');
        console.log(token);
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Authorization', 'Bearer ' + token);
        return headers;
    }

    /**
     * Gets the request type based on the datamarshaller enum.
     * @param endpoint : DataMarshallerEndPoints  - The endpoint you wish to call
     * @return A request type enum for Post/Get
     */
    _getRequestTypeForGridEdgeApiRequestEndpoint(endpoint: DataMarshallerEndPoints): RequestType {
        switch (endpoint) {
            case DataMarshallerEndPoints.SITE_LIST:
            case DataMarshallerEndPoints.SITE_INFO:
                return RequestType.GET;
        }
    }

    /**
     * Gets the page name that will be appended to the URL for datamarshaller request endpoints.
     * This can then be appended to the datamarshaller url
     * @param endpoint - The endpoint you wish to call
     * @return : String - The page name
     */
    _getURLForApi(endpoint: DataMarshallerEndPoints): string {
        switch (endpoint) {
            case DataMarshallerEndPoints.SITE_INFO:
                return environment.api_urls.SITE_INFO;
            case DataMarshallerEndPoints.SITE_LIST:
                return environment.api_urls.SITE_LIST;
        }
    }
}

import {DateRangePickerComponent} from './date-range-picker/date-range-picker.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {DatePickerComponent} from './date-picker/date-picker.component';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgModule} from '@angular/core';
import {MarkdownModule} from 'ngx-markdown';
import {AuthModule} from '../../../auth/auth.module';
import {MatModulesModule} from '../mat-modules/mat-modules.module';
import {MaxRangeDirective} from './date-range-picker/mat-range-directive';
import {LoadingPageComponent} from './loading-page/loading-page.component';

const sharedComponents = [

  DatePickerComponent,

  DateRangePickerComponent
];

const sharedModules = [
  CommonModule,
  MatDatepickerModule,
  FormsModule,
  ReactiveFormsModule,
  MatModulesModule
];

@NgModule({
    declarations: [
        sharedComponents,
        MaxRangeDirective,
        LoadingPageComponent,

    ],
  imports: [sharedModules, MarkdownModule, AuthModule],
    exports: [sharedModules, sharedComponents, LoadingPageComponent],
  providers: [MatDatepickerModule],

})
export class SharedModule {
}

import {Component, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../services/auth/auth.service';
import {FIREBASE_FUNCTIONS, FirebaseService} from '../../services/firebase/firebase.service';
import {RecaptchaComponent} from 'ng-recaptcha';
import {environment} from 'src/environments/environment';
import {GeneralService} from '../../../services/generalService/general.service';
import {SnackBarService} from '../../../services/snackBar/snack-bar.service';
import { URL_PATHS } from 'src/app/data/Enums';


@Component({
    selector: 'app-login-page',
    templateUrl: './login-page.component.html',
    styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {

    environment = environment;
    email: UntypedFormControl;
    errorMessage: string;
    loginForm: UntypedFormGroup;
    @ViewChild('captchaRef', {static: true}) captchaRef: RecaptchaComponent;

    hasSentEmail = false;
    isSendingEmail = false;

    constructor(public snackBarService: SnackBarService, public generalService: GeneralService, public firebaseService: FirebaseService, public authService: AuthService) {
        // If we have loaded the login set the loading field to false
        // this will prevent previous login from hiding the input fields
        this.authService.isLoggingTheUserIn = false;
    }

    ngOnInit() {
        this.email = new UntypedFormControl('', [Validators.email, Validators.pattern(this.authService.emailRegex.source), Validators.required]);

        this.loginForm = new UntypedFormGroup({
            email: this.email,
            checkBox: new UntypedFormControl(false, Validators.requiredTrue)
        });
    }


    /**
     * When they click the checkbox
     * @param event checkbox change event
     */
    onDataPolicyChecked(event) {
        this.loginForm.get('checkBox').setValue(event.checked);
    }


    /**
     * @return the text that has been typed into the email box
     */
    getEmail() {
        return this.loginForm.get('email').value;
    }


    /**
     * Login form submitted
     * We call the recaptcha and start the loading spinner
     * When recaptcha replies it will call recaptchaResponseHandler and
     * pass a token
     */
    callRecaptcha() {
        // show the loading spinner
        this.isSendingEmail = true;
        this.captchaRef.execute();
    }


    /**
     * Submits the form
     * Will send an login email to the user if its valid
     */
    async submitForm(recaptchaToken) {
        const email = this.getEmail();
        if (recaptchaToken != null) {
            if (this.generalService.passesEmailRegex(email)) {
                try {
                    const res = await this.firebaseService.callFirebaseFunction(FIREBASE_FUNCTIONS.SEND_LOGIN_LINK_EMAIL, {
                        email,
                        recaptchaToken,
                        url: window.location.origin + '/' + URL_PATHS.EMAIL_LINK_LANDING,
                        tenantID: environment.tenantId
                    });
                    if (res.statusCode === 200) {
                        window.localStorage.setItem('emailForSignIn', email);
                        this.hasSentEmail = true;
                    } else {
                        this.snackBarService.openSnackBar('Failed to generate login email');
                    }
                } catch (e) {
                    console.log(e);
                    this.snackBarService.openSnackBar('Failed to generate login email');
                }
                this.isSendingEmail = false;
            }
        }
    }


    /**
     * Will fire when a recaptcha error occurs
     */
    handleRecaptchaError() {
        this.snackBarService.openSnackBar('Failed to pass recaptcha challenge');
    }

    public get URL_PATHS(): typeof URL_PATHS {
        return URL_PATHS;
    }

    /**
     * Resets the form
     * Sets the checkbox to unchecked and clears the email that has been typed in
     */
    resetForm() {
        this.loginForm.reset();
        this.hasSentEmail = false;
    }
}

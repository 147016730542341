<div class="verticalFlex">
    <form (ngSubmit)="submit()" [hidden]="isLoading == true" class="flex-1 verticalFlex" [formGroup]="addEditForm">
        <div class="verticalFlex w-100">
            <h3 *ngIf="organisationToEdit == null">Add New User Group</h3>
            <h3 *ngIf="organisationToEdit != null">Edit User Group</h3>

            <mat-form-field *ngIf="orgNameFormControl != null" class="pt-2 pb-2 w-100" appearance="fill">
                <mat-label>User Group Name</mat-label>
                <input matInput style="text-transform: lowercase" [formControl]="orgNameFormControl" [maxLength]="MAX_ORGANISATION_NAME_LENGTH">
                <mat-error *ngIf="orgNameFormControl.hasError('required')">
                  Please provide an user group name
                </mat-error>
            </mat-form-field>

            <div *ngIf="orgNameFormControl == null" class="py-2">
                <h4>{{organisationToEdit.name}}</h4>
            </div>

            <section class="verticalFlex siteListBorder">
                <mat-form-field class="px-2 pt-2 w-100" appearance="fill">
                    <mat-label>Site Name Filter</mat-label>
                    <input matInput [(ngModel)]="organisationFilter" [ngModelOptions]="{standalone: true}"/>
                </mat-form-field>
                <div class="flex-1  pb-2" style="min-height: 0;overflow-y: scroll">
                    <div class="px-2" *ngFor="let controlObj of siteFormArray.controls; let i = index">
                        <div class="pt-1" [hidden]="getCheckboxLabel(allSitesUUIDs[i]).toLowerCase().includes(organisationFilter.toLowerCase()) == false">
                            <mat-checkbox [formControl]="controlObj"
                                          color="primary">
                                <div class="pl-2 pr-2" style="width: 100%;white-space: initial;">
                                    {{getCheckboxLabel(allSitesUUIDs[i])}}
                                </div>
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
            </section>
            <mat-error>
                <div class="w-100 text-center" *ngIf="siteFormArray.touched && siteFormArray.hasError('one_checkbox_required')">Please select at least 1 Site to add to the user group
                </div>
            </mat-error>
        </div>

        <div class="pt-3 d-flex justify-content-center align-items-center">
            <div class="mx-2">
                <button
                        type="button"
                        mat-raised-button
                        (click)="dialogRef.close()"
                        color="primary">
                    Cancel
                </button>
            </div>
            <div>
                <button mat-raised-button
                        type="submit"
                        [disabled]="addEditForm.invalid == true || addEditForm.untouched == true"
                        color="primary">
                    Submit
                </button>
            </div>




        </div>
    </form>

    <div [hidden]="isLoading == false">
        <app-loading-spinner></app-loading-spinner>
    </div>
</div>

import {ChangeDetectorRef, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import {FormsModule} from '@angular/forms';
import {MatTableModule} from '@angular/material/table';
import {UserLoginHistoryTableComponent} from './login-history-table/user-login-history-table.component';
import { UserStatsPageComponent } from './user-stats-page/user-stats-page.component';
import {SharedModule} from '../shared/shared.module';



@NgModule({
  declarations: [UserLoginHistoryTableComponent, UserStatsPageComponent],
  exports: [
    UserLoginHistoryTableComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatFormFieldModule,
    FormsModule,
    MatInputModule,
    MatSelectModule,
    MatTableModule,
    SharedModule,
  ]
})
export class UserStatsModule { }

import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AuthModule} from './auth/auth.module';
import {environment} from '../environments/environment';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ModalsModule} from './components/modules/modals/modals.module';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {AuthConfigModule} from './components/modules/auth-config/auth-config.module';
import {HttpClientModule} from '@angular/common/http';
import {DateAdapter, MAT_DATE_LOCALE} from '@angular/material/core';
import {AngularFireModule} from '@angular/fire/compat';
import {AngularFireDatabaseModule} from '@angular/fire/compat/database';
import {AngularFireFunctions, AngularFireFunctionsModule, REGION} from '@angular/fire/compat/functions';
import {AngularFireStorage, AngularFireStorageModule} from '@angular/fire/compat/storage';
import {AngularFireAuthModule, TENANT_ID} from '@angular/fire/compat/auth';
import {AngularFirestoreModule} from '@angular/fire/compat/firestore';
import {SharedModule} from './components/modules/shared/shared.module';
import {DefaultMatCalendarRangeStrategy, MAT_DATE_RANGE_SELECTION_STRATEGY} from '@angular/material/datepicker';
import {CustomDateAdapter} from './components/modules/shared/date-picker/date-picker.component';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFireFunctionsModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
    AngularFirestoreModule,
    BrowserModule,
    AuthModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgbModule,
    AuthConfigModule,
    ModalsModule,
    MatDialogModule,
    MatSnackBarModule,
    SharedModule,
    HttpClientModule
  ],
  providers: [
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: DefaultMatCalendarRangeStrategy,
    },
    {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
    {provide: REGION, useValue: 'europe-west1'},
    { provide: TENANT_ID, useValue: environment.tenantId },
    {provide: DateAdapter, useClass: CustomDateAdapter},
    AngularFireFunctions, AngularFireStorage, Location

  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}

<div class="container-fluid authCardholder">
    <app-page-background-image></app-page-background-image>
    <mat-card class="authCard text-center">
        <div class="container holder d-flex flex-column h-100">
            <app-auth-card-header></app-auth-card-header>
            <div *ngIf="isLoading == true" class="d-flex flex-1 justify-content-center align-items-center">
                <div class="la-ball-grid-pulse la-3x">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>

            <div *ngIf="isLoading == false" class="flex-1 d-flex flex-column align-items-center justify-content-center">

                <div *ngIf="successMessage != null">
                    <div>{{successMessage}}</div>
                    <a class="hyperlink" [routerLink]="['']">Return to Login</a>
                </div>

                <div *ngIf="successMessage == null">
                    <div *ngIf="handlerMode == EMAIL_ACTION_HANDLER_MODES.RESET_PASSWORD">
                        <form [formGroup]="resetPasswordForm" (ngSubmit)="callRecaptcha()" autocomplete="off">
                            <mat-form-field class="w-100 noPaddingTopFormInput"
                                            appearance="outline">

                                <input matInput formControlName="password"
                                       type="password"
                                       autocomplete="new-password"
                                       placeholder="Password"/>
                                <mat-icon matSuffix [matTooltip]="passwordRules" style="position: relative; top: 4px"
                                          color="primary">info
                                </mat-icon>

                                <mat-error *ngIf="password.hasError('pattern')">
                                    Password is <strong>invalid</strong>. Please see     <mat-icon matSuffix [matTooltip]="passwordRules" style="top: 2px"
                                                                                                   color="primary">info
                                </mat-icon> for details
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field floatLabel=never class="w-100 noPaddingTopFormInput"
                                            appearance="outline">

                                <input matInput formControlName="passwordConfirmation"
                                       type="password"
                                       autocomplete="new-password"
                                       placeholder="Password Confirmation"/>
                                <mat-icon matSuffix [matTooltip]="passwordRules" style="position: relative; top: 4px"
                                          color="primary">info
                                </mat-icon>

                                <mat-error *ngIf="resetPasswordForm.valid == false">
                                    Password does not match
                                </mat-error>

                            </mat-form-field>

                            <div class="text-center">
                                <button id="changePasswordButton" mat-raised-button type="submit"
                                        class="mt-4 mb-3 button"
                                        color="primary" [disabled]="resetPasswordForm.invalid">CHANGE
                                    PASSWORD
                                </button>
                            </div>
                        </form>
                    </div>


                    <div *ngIf="handlerMode == EMAIL_ACTION_HANDLER_MODES.RECOVER_EMAIL">
                        <div>YOU REQUESTED RECOVER EMAIL</div>
                    </div>

                    <div *ngIf="handlerMode == EMAIL_ACTION_HANDLER_MODES.VERIFY_EMAIL">
                        <div>YOU REQUESTED VERIFY EMAIL</div>
                    </div>


                    <div class="errorText" *ngIf="errorMessage !== ''">
                        <mat-error class="error marginCommonErrorMessage">
                            {{errorMessage}}
                        </mat-error>
                    </div>

                </div>
            </div>

        </div>
    </mat-card>


    <re-captcha #captchaRef="reCaptcha" (resolved)="recaptchaReposeHandler($event)"
                (error)="recaptchaErrorHandler()" [siteKey]="environment.recaptchaKey"
                size="invisible"></re-captcha>

</div>

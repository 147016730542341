import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {Subscription} from 'rxjs';
import {Edge2xUsersService} from '../../../../services/Edge2xUsersService/edge2x-users.service';
import {LoginEvent} from '../../../../data/LoginEvent';
import {DateRange} from '@angular/material/datepicker';
import {AvailableDates} from '../../../../data/AvailableDates';
import {TimeService} from '../../../../services/time/time.service';
import {TimeZoneEnum} from '../../../../data/Enums';
import {GeneralService} from '../../../../services/generalService/general.service';

@Component({
    selector: 'app-user-stats-page',
    templateUrl: './user-stats-page.component.html',
    styleUrls: ['./user-stats-page.component.scss']
})
export class UserStatsPageComponent implements OnInit, OnDestroy {

    selectedDateRange: DateRange<string>;
    edge2xUsersChangedSubscription: Subscription;

    displayedColumns: string[] = ['uid', 'email', 'date', 'time'];
    dataSource: MatTableDataSource<{
        uid: string,
        email: string,
        date: string,
        time: string
    }> = new MatTableDataSource();

    availableDates: AvailableDates;

    constructor(public generalService: GeneralService, private timeService: TimeService, public edge2xUsersService: Edge2xUsersService) {
    }

    ngOnInit(): void {
        this.edge2xUsersChangedSubscription = this.edge2xUsersService.users$.subscribe(newUsers => {
            console.log('New users received');
            this.buildAvailableDates();
            this.buildTableData();
        });
        this.buildAvailableDates();
        this.buildTableData();
    }

    ngOnDestroy(): void {
        this.edge2xUsersChangedSubscription.unsubscribe();
    }

    handleNewDatesPicked(newDateRange) {
        this.selectedDateRange = newDateRange;
        this.buildTableData();
    }

    buildTableData() {
        const newTableRows = [];

        const startTimestamp = this.timeService.stringToMomentObject(this.selectedDateRange.start, 'YYYY-MM-DD', TimeZoneEnum.LOCAL).startOf('day').valueOf();
        const endTimestamp = this.timeService.stringToMomentObject(this.selectedDateRange.end, 'YYYY-MM-DD', TimeZoneEnum.LOCAL).endOf('day').valueOf();

        for (let i = 0; i < this.edge2xUsersService.users.length; i++) {
            const user = this.edge2xUsersService.users[i];
            for (const entry of Array.from(user.loginEvents.entries())) {
                const pushId = entry[0];
                const loginEvent: LoginEvent = entry[1];

                const parsedTimestamp = parseInt(loginEvent.timestamp, 10);
                if (parsedTimestamp > startTimestamp && parsedTimestamp < endTimestamp) {
                    newTableRows.push({
                        uid: user.uid,
                        timestamp: loginEvent.timestamp,
                        email: user.email,
                        date: loginEvent.date,
                        time: loginEvent.time
                    });
                } else {
                    console.log('Skipping as outside of selected range');
                }

            }
        }
        newTableRows.sort((a, b) => {
            return a.timestamp < b.timestamp ? 1 : -1;
        });
        this.dataSource = new MatTableDataSource(newTableRows);
    }

    buildAvailableDates() {
        let smallestTimestamp = null;
        let largestTimestamp = null;
        for (let i = 0; i < this.edge2xUsersService.users.length; i++) {
            const user = this.edge2xUsersService.users[i];
            for (const entry of Array.from(user.loginEvents.entries())) {
                const loginEvent: LoginEvent = entry[1];

                const timestamp = loginEvent.timestamp;

                if (smallestTimestamp == null || timestamp < smallestTimestamp) {
                    smallestTimestamp = timestamp;
                }

                if (largestTimestamp == null || timestamp > largestTimestamp) {
                    largestTimestamp = timestamp;
                }
            }
        }

        const startTimestampMoment = this.timeService.timestampToMomentObject(smallestTimestamp, TimeZoneEnum.LOCAL);
        const largestTimestampMoment = this.timeService.timestampToMomentObject(largestTimestamp, TimeZoneEnum.LOCAL);

        const json = [];
        do {
            startTimestampMoment.startOf('day');
            const day = {ActiveDay: startTimestampMoment.valueOf()};
            json.push(day);
            startTimestampMoment.add(1, 'day');
        } while (startTimestampMoment.valueOf() < largestTimestampMoment.valueOf());

        const availableDates = AvailableDates.fromJSON(json, 'Europe/London');
        this.availableDates = availableDates;

        this.selectedDateRange = new DateRange<string>(largestTimestampMoment.clone().subtract(7, 'days').format("YYYY-MM-DD"), largestTimestampMoment.format("YYYY-MM-DD"));
    }

    /**
     * yyyy-mm-dd date will become DD/MM/YYYY date
     * @param date: string
     */
    getFormattedDate(date: string) {
        return this.timeService.stringToMomentObject(date, 'YYYY-MM-DD', TimeZoneEnum.LOCAL).format('DD/MM/YYYY');
    }
}

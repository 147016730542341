import {Injectable} from '@angular/core';
import {Subscription} from 'rxjs';
import {UntypedFormGroup, FormGroupDirective} from '@angular/forms';
import {AuthService} from '../../auth/services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {
  readonly USER_LOGIN_EVENT_EXPLANATION_TEXT = 'The login user events are moments where the users have seen the login spinner after refreshing the page OR actually logging in. These are the only 2 cases where events are recorded. If they leave the tab open and prevent their computer from sleeping the usage will not be recorded.';


  constructor(private authService: AuthService) {

  }


  // Loading messages
  PLEASE_WAIT_LOADING_MESSAGE = 'PLEASE WAIT';
  FETCHING_DATA_LOADING_MESSAGE = 'FETCHING DATA';
  LOGGING_USER_IN_LOADING_MESSAGE = 'Let\'s get you started...';

  /**
   * Will check whether a given email is a valid email address
   * @param emailString: string - the email to check
   */
  passesEmailRegex(emailString: string): boolean {
    if (emailString == null) {
      return false;
    }
    return emailString.match(this.authService.emailRegex) != null;
  }

  /**
   * Function that will compare 2 formControl values and set an error on the one which needs to match if failure
   * @param controlName - FormControl to match with
   * @param matchingControlName - FormControl which needs to match the first one
   */
  matchInputs(controlName: string, matchingControlName: string) {
    return (formGroup: UntypedFormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.NotEqual) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({NotEqual: true});
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  /**
   * Cancels the given subscription
   * If its not subscribed nothing will happen
   * and no error will be thrown
   */
  tryUnsubscribeObservable(subscription: Subscription) {
    // https://stackoverflow.com/questions/36490926/how-to-cancel-a-httprequest-in-angular-2
    if (subscription != null) {
      subscription.unsubscribe();
      console.log('Cancelled http request');
    }
  }
}

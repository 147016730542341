import {Component, Input, OnInit} from '@angular/core';
import {Edge2xUser} from '../../../../data/edge2xUser';
import {MatTableDataSource} from '@angular/material/table';
import {TimeZoneEnum} from '../../../../data/Enums';
import {TimeService} from '../../../../services/time/time.service';

@Component({
  selector: 'app-user-login-history-table',
  templateUrl: './user-login-history-table.component.html',
  styleUrls: ['./user-login-history-table.component.scss']
})
export class UserLoginHistoryTableComponent implements OnInit {

  @Input() user: Edge2xUser;

  displayedColumns: string[] = ['date', 'time'];
  dataSource: MatTableDataSource<{
    date: string,
    time: string
  }> = new MatTableDataSource();

  constructor(private timeService: TimeService) {
  }

  ngOnInit(): void {
    const data = [];
    for (const entry of Array.from(this.user.loginEvents.entries())) {
      const key = entry[0];
      const value = entry[1];
      data.push({
        date: value.date,
        time: value.time,
        timestamp: value.timestamp
      });
    }

    data.sort((a, b) => {
      return a.timestamp < b.timestamp ? 1 : -1;
    });
    this.dataSource = new MatTableDataSource(data);
    console.log(this.dataSource);
  }

  /**
   * yyyy-mm-dd date will become DD/MM/YYYY date
   * @param date: string
   */
  getFormattedDate(date: string) {
    return this.timeService.stringToMomentObject(date, 'YYYY-MM-DD', TimeZoneEnum.LOCAL).format('DD/MM/YYYY');
  }

}

export class SiteConfig {
  uuid: string;
  name: string;
  displayName: string;
  organisationName: string;


  constructor(uuid: string, name: string, displayName: string, organisationName: string) {
    this.uuid = uuid;
    this.name = name;
    this.displayName = displayName;
    this.organisationName = organisationName;
  }

  static fromConfigJson(json): SiteConfig {
    const uuid = json['uuid'];
    const name = json['name'];
    const displayName = json['displayName'];
    const organisationName = json['organisationName'];

    return new SiteConfig(uuid, name, displayName, organisationName);
  }

  getDisplayNameOrUUIDIfNull() {
    if(this.displayName != null) {
      return this.displayName;
    }
    return this.uuid;
  }
}

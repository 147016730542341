<div appBlockZoom style="height:100%; overflow: hidden;position: relative" class="verticalFlex">

  <div *ngIf="shouldShowNavBar() === true"
       class="primaryBackGroundColor px-4 d-flex position-relative align-items-center "
       style="height:56px">


    <app-loading-page></app-loading-page>

    <div (click)="pageNavigatorService.loadAuthConfigPage()" class="navbarButton pl-3">User Manager</div>
    <div (click)="pageNavigatorService.loadUserStatsPage()" class="navbarButton pl-5">User Stats</div>
    <div (click)="pageNavigatorService.loadUserGroupsPage()" class="navbarButton pl-5">User Groups</div>
    <div class="flex-1"></div>
    <div class="navbarButton pr-3" (click)="logout()">Logout</div>
  </div>

  <div class="contentPageHolder">
    <router-outlet></router-outlet>
  </div>
</div>

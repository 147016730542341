export enum URL_PATHS {
  LOGIN = '',
  LOGIN_PASSWORD = 'login-password',
  PASSWORD_RESET = 'password-reset',
  DATA_POLICY = 'data-policy',
  EMAIL_ACTION_HANDLER = 'email-action-handler',
  EMAIL_LINK_LANDING = 'emaillinklanding',

  AUTH_CONFIG_PAGE = 'user-manager',
  USER_STATS_PAGE = 'user-stats',
  USER_GROUPS_PAGE = 'user-groups',
}
export enum EMAIL_ACTION_HANDLER_MODES {
  RESET_PASSWORD = 'Reset password',
  RECOVER_EMAIL = 'Recover email',
  VERIFY_EMAIL = 'Verify email'
}

export enum TimeZoneEnum {
  UTC = 'UTC',
  LOCAL = 'LOCAL',
}

export enum DataMarshallerEndPoints {
  SITE_INFO = 'SITE_INFO',
  SITE_LIST = 'SITE_LIST',
}

export enum RequestType {
  POST = 'POST',
  GET = 'GET',
}

import {Component, OnInit} from '@angular/core';
import {LoadingService} from '../../../../services/loading/loading.service';
import {GeneralService} from '../../../../services/generalService/general.service';
@Component({
    selector: 'app-loading-page',
    templateUrl: './loading-page.component.html',
    styleUrls: ['./loading-page.component.scss']
})
export class LoadingPageComponent implements OnInit {

    constructor(public loadingService: LoadingService, private generalService: GeneralService) {
    }

    ngOnInit() {
    }


}

import Color from 'color';
import * as moment from 'moment';
import 'moment-timezone';

export class StaticFunctions {

    static turnDecmialPercentTo100Percent(deciamalPercent: number): number {
        const multipliedValue = deciamalPercent * 100;
        return multipliedValue;
    }


    static round(value: number, digits: number) {
        value = Number(value);
        return Number(value.toFixed(digits));
    }

    /**
     * Will merge two json objects
     * If they both have the same key the second param takes precedence
     * @param obj1: {} - first object
     * @param obj2: {} - second object
     * @return - the combined json object
     */
    static mergeTwoJsonObjects(obj1, obj2) {
        const result = {};
        let key;

        for (key in obj1) {
            if (obj1.hasOwnProperty(key)) {
                result[key] = obj1[key];
            }
        }

        for (key in obj2) {
            if (obj2.hasOwnProperty(key)) {
                result[key] = obj2[key];
            }
        }
        return result;
    }


    /**
     * This will add 15 minutes to a timestamp
     * @param timestamp: number - the timestamp in milliseconds
     * @return a millisecond timestamp 15 minutes after the parameter
     */
    static add15MinutesToTimestamp(timestamp: number) {
        return timestamp + 900000;
    }

    /**
     * Will convert a colour object to json so it can be stored on firebase storage during saves
     * @param color: Color - the color object to convert to json
     */
    static turnColorToJSON(color: Color) {
        return color.object();
    }

    /**
     * This will convert json representing Color objects back into Color objects
     * They should have been converted to json using 'turnColorToJSON'
     * @param json: {} - the json representing the color object
     */
    static deserialiseJSONToColor(json) {
        return Color(json);
    }

    /**
     * This will turn a timestamp and a timezone into a moment object
     * @param timetamp: number - the timestamp you want to convert
     * @param timezone: string - a timezone e.g. 'Europe/London'
     */
    static convertTimestampToTimeZonedMoment(timestamp: number, timezone: string) {
        return moment(timestamp).tz(timezone);
    }

    static isJSObjEmpty(obj) {
        return Object.keys(obj).length === 0;
    }


    static isNumeric(str: number) {
        if (typeof str !== 'string') {
            return false;
        } // we only process strings!
        return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
            !isNaN(parseFloat(str)); // ...and ensure strings of whitespace fail
    }

}

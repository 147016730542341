<mat-calendar #matCalendar *ngIf="isCalendarOnly" (selectedChange)="_onSelectedChange($event)"
              [dateFilter]="filterDatesCaller"
              [startAt]="localDateRange != null ? localDateRange.start : null"
              [maxRange]="sizeRangeSelection"
              [selected]="localDateRange">
</mat-calendar>

<form [formGroup]="range">
  <mat-form-field *ngIf="!isCalendarOnly" appearance="fill" class="centerIcon">
    <mat-label class="nonDisabledFont">Select a date range</mat-label>
    <mat-date-range-input [dateFilter]="filterDatesCaller"
                          [maxRange]="sizeRangeSelection"
                          [rangePicker]="picker">
      <input matStartDate formControlName="start" placeholder="Start date" class="nonDisabledFont" disabled>
      <input matEndDate formControlName="end" placeholder="End date" class="nonDisabledFont" disabled>
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker" disabled="false"></mat-datepicker-toggle>
    <mat-date-range-picker #picker disabled="false" [maxRange]="sizeRangeSelection"></mat-date-range-picker>
    <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
    <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
  </mat-form-field>
</form>

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss']
})
export class LoadingSpinnerComponent implements OnInit {

  FETCHING_DATA = 'FETCHING DATA';
  ERROR_FETCHING_DATA = 'ERROR WHILE FETCHING DATA';

  @Input() error?: boolean;

  @Input() buttonName?: string;

  @Input() hasBackground = false;

  @Output() clickedButton = new EventEmitter();

  @Input() loadingText: string;

  @Input() isSmallText: boolean = false;


  constructor() {
  }

  ngOnInit() {}

  /**
   * This will emit an event using the clickedButton event emitter
   */
  emmitClick() {
    this.clickedButton.emit();
  }

}

import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AuthService} from '../../../../auth/services/auth/auth.service';
import {OrganisationSitesService} from '../../../../services/organisationSitesService/organisation-sites.service';
import {
    AbstractControl,
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import {GeneralService} from '../../../../services/generalService/general.service';
import {Organisation} from '../../../../data/Organisation';
import {FirebaseService} from '../../../../auth/services/firebase/firebase.service';
import {SnackBarService} from '../../../../services/snackBar/snack-bar.service';

@Component({
    selector: 'app-add-edit-organisation-modal',
    templateUrl: './add-edit-organisation-modal.component.html',
    styleUrls: ['./add-edit-organisation-modal.component.scss']
})
export class AddEditOrganisationModalComponent implements OnInit {

    MAX_ORGANISATION_NAME_LENGTH = 30;

    allSitesUUIDs: {
        uuid: string,
        displayName: string
        organisationName: string
    }[];
    organisationToEdit: Organisation;

    addEditForm: UntypedFormGroup;
    orgNameFormControl: UntypedFormControl;
    siteFormArray: UntypedFormArray;

    isLoading: boolean = false;

    organisationFilter: string = '';

    constructor(@Inject(MAT_DIALOG_DATA) data, public dialogRef: MatDialogRef<AddEditOrganisationModalComponent>,
                private authService: AuthService, public organisationSitesService: OrganisationSitesService, private fb: UntypedFormBuilder,
                private generalService: GeneralService, private firebaseService: FirebaseService, private snackBarService: SnackBarService) {
        this.allSitesUUIDs = this.organisationSitesService.getSitesUUIDsOrderedByName(Array.from(data.allSiteUUIDS));
        this.organisationToEdit = data.organisation;
    }


    ngOnInit(): void {
        const checkboxFormControls: UntypedFormControl[] = [];

        this.allSitesUUIDs.forEach((siteObj) => {
            let alreadyChecked = false;
            if (this.organisationToEdit?.sites.has(siteObj.uuid)) {
                alreadyChecked = true;
            }
            checkboxFormControls.push(new UntypedFormControl(alreadyChecked));
        });

        this.siteFormArray = new UntypedFormArray(checkboxFormControls, (control: AbstractControl) => {
            const checkboxFormArray: UntypedFormArray = (control as UntypedFormArray);
            let hasOneChecked: boolean = false;
            for (let i = 0; i < checkboxFormArray.controls.length; i++) {
                const formControl: UntypedFormControl = (checkboxFormArray.controls[i] as UntypedFormControl);
                if (formControl.value === true) {
                    hasOneChecked = true;
                    break;
                }
            }
            if (hasOneChecked === true) {
                return null;
            }
            return {one_checkbox_required: true};
        });

        if (this.organisationToEdit != null) {
            // dont let them edit the name if it already exists
            this.addEditForm = new UntypedFormGroup({
                checkboxes: this.siteFormArray
            });
        } else {
            this.orgNameFormControl = new UntypedFormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z_]+$/)]);
            this.addEditForm = new UntypedFormGroup({
                orgNameFormControl: this.orgNameFormControl,
                checkboxes: this.siteFormArray
            });
        }
    }

    /**
     * get the path to the correct node on Firebase for the given organisation name
     * @param organisationName string
     */
    getPathToOrganisation(organisationName: string) {
        return this.organisationSitesService.ORGANISATIONS_TO_SITE_DB_PATH + '/' + organisationName;
    }

    /**
     * submit the dialog form
     *
     */
    async submit() {
        if (this.addEditForm.invalid === false) {
            let organisationName = null;
            if (this.organisationToEdit == null) {
                organisationName = this.orgNameFormControl.value.toLowerCase();
            } else {
                organisationName = this.organisationToEdit.name;
            }
            const nodePath = this.getPathToOrganisation(organisationName);

            const selectedSiteUUIDs: string[] = [];

            for (let i = 0; i < this.allSitesUUIDs.length; i++) {
                if (this.siteFormArray.controls[i].value === true) {
                    selectedSiteUUIDs.push(this.allSitesUUIDs[i].uuid);
                }
            }
// Set the appropriate Firebase node for the organisation with the new or edited list of sites
            try {
                await this.firebaseService.setDatabaseNode(nodePath, selectedSiteUUIDs, null);
                const org: Organisation = new Organisation(organisationName, new Set<string>(selectedSiteUUIDs));
                const verb = this.organisationToEdit == null ? 'added' : 'updated';
                this.snackBarService.openSnackBar('Successfully ' + verb + ' the organisation');
                this.dialogRef.close(org);
            } catch (e) {
                console.log(e);
                const verb = this.organisationToEdit == null ? 'add' : 'update';
                this.snackBarService.openSnackBar('Failed to ' + verb + ' the organisation');
            }
        }
    }


    getCheckboxLabel(siteObj: {
        uuid: string,
        displayName: string
        organisationName: string
    }) {
        return siteObj.displayName + ' (' + siteObj.organisationName + ')';
    }
}

<div class="verticalFlex">
  <span class="px-4 py-2 page-title">Edge2x Users</span>

  <mat-form-field class="px-4 pt-1 w-100" appearance="fill">
    <mat-label>Filter users via email, uid or user group</mat-label>
    <input matInput [(ngModel)]="filterText" (ngModelChange)="updateFilteredUsers()">
    <div class="cursorPointer" *ngIf="filterText" matSuffix aria-label="Clear"
         (click)="filterText='';updateFilteredUsers()">
      <mat-icon>close</mat-icon>
    </div>
  </mat-form-field>
  <div class="px-4 d-flex">
    <div class="flex-1"></div>

    <div>
      <div>
        <mat-form-field appearance="fill">
          <mat-label>Sort By:</mat-label>
          <mat-select [(value)]="selectedSort" (valueChange)="updateFilteredUsers()">
            <mat-option [value]="SORT_ENUM.EMAIL">Email</mat-option>
            <mat-option [value]="SORT_ENUM.FIRSTNAME">Firstname</mat-option>
            <mat-option [value]="SORT_ENUM.LASTNAME">Lastname</mat-option>
            <mat-option [value]="SORT_ENUM.USER_GROUP">User Group</mat-option>
            <mat-option [value]="SORT_ENUM.UID">UID</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div style="font-style: italic">Showing {{this.filteredUsers.length}} of {{edge2xUsersService.users.length}} total users</div>
    </div>


  </div>
  <div class="px-4 flex-1 overflow-y-auto">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-4 col-sm-6 mb-2 py-0 px-1">
          <div class="card cursorPointer h-100 p-3 position-relative" (click)="addEdge2xUser()">
            <div class="d-flex verticalFlex justify-content-center align-items-center">
              <mat-icon class="plusIcon">add_circle_outline</mat-icon>
              <div>Add User</div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-sm-6 mb-2 py-0 px-1" *ngIf="this.filteredUsers.length == 0">
          <div class="card cursorPointer h-100 p-3 position-relative">
            <div class="d-flex verticalFlex justify-content-center align-items-center">
              <mat-icon class="notFoundIcon">help_outline</mat-icon>
              <div>No matching users.</div>
              <div>(if you expect users make sure there isn't a filter applied)</div>
            </div>
          </div>
        </div>
        <div *ngFor="let user of filteredUsers" class="col-lg-4 col-sm-6 mb-2 py-0 px-1">
          <div class="card h-100 p-3 position-relative">
            <div class="p-3" style="position: absolute;top:0;right: 0">
              <mat-icon (click)="openLoginHistoryDialog(user)" class="mx-2 editIcon">timeline</mat-icon>
              <mat-icon (click)="editEdge2xUser(user)" class="mx-2 editIcon">edit</mat-icon>
              <mat-icon (click)="deleteEdge2xUser(user)" class="mx-2 editIcon">delete</mat-icon>
            </div>
            <div class="pt-2">
              <h4>{{user.userProfile.firstname}} {{user.userProfile.lastname}}</h4>
              <h5>{{user.email}}</h5>
              <h6>{{user.uid}}</h6>
              <div>Operator: {{user.operator ?? 'unset'}}</div>
              <div>Role: {{user.role}}</div>
            </div>
            <div class="pt-3">
              <div>User Group</div>
              <div class="trimText">{{user.organisation}}</div>
            </div>

            <div class="pt-3">
              <div>Sites</div>
              <div style="height: 300px;overflow-y: auto">
                <div class="row no-gutters ">
                  <div *ngFor="let siteConfig of userUIDToOrderedSites.get(user.uid)"
                       class="col-4">
                    <div class="trimText pr-2">{{siteConfig.getDisplayNameOrUUIDIfNull()}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {OrganisationSitesService} from '../../services/organisationSitesService/organisation-sites.service';
import {FIREBASE_FUNCTIONS, FirebaseService} from '../../auth/services/firebase/firebase.service';
import {Edge2xUsersService} from '../../services/Edge2xUsersService/edge2x-users.service';
import {GridEdgeApiRequestService} from '../../services/gridEdgeApiRequest/grid-edge-api-request.service';
import {DataMarshallerEndPoints} from '../../data/Enums';
import {SiteConfig} from '../../data/SiteConfig';
import {HttpParams, HttpResponse} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class LoginResolveService implements Resolve<null> {

    userData;

    constructor(private gridEdgeApiRequestService: GridEdgeApiRequestService, private firebaseService: FirebaseService, private edge2xUsersService: Edge2xUsersService, private organisationSitesService: OrganisationSitesService) {

    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<null> | Promise<null> | null {


        return new Promise(async (resolve, reject) => {

            if (this.edge2xUsersService.users == null || this.organisationSitesService.allSiteUUIDS == null) {
                const res = await this.getUsersAndSitesToOrganisationsData();

                this.edge2xUsersService.setUpUsers(res.users, res.loginEvents);

                const siteConfigs: Map<string, SiteConfig> = await this.getSiteNamingConfig();
                this.organisationSitesService.siteUUIDToConfigMap = siteConfigs;

                this.organisationSitesService.loadSitesAndOrganisations(res.sitesToOrganisations, Array.from(siteConfigs.keys()));

            }
            // @ts-ignore
            resolve();
        });
    }

    /**
     * This fetches two things:
     * Existing user data from a call to Firebase Functions
     * Site and organisation data from Firebase database
     * The json for users and sitesToOrganisations will be returned
     */
    async getUsersAndSitesToOrganisationsData(): Promise<{
        users: any,
        sitesToOrganisations: any,
        loginEvents: any
    }> {
        try {
            const promises = [];
            promises.push(this.firebaseService.callFirebaseFunction(FIREBASE_FUNCTIONS.GET_ALL_USERS_IN_USER_TENANT, {}));
            promises.push(this.firebaseService.readFirebaseNode(this.organisationSitesService.ORGANISATIONS_TO_SITE_DB_PATH));
            promises.push(this.firebaseService.readFirebaseNode(this.organisationSitesService.LOGIN_EVENTS_DB_PATH));

            const data = await Promise.all(promises);

            const usersRes = data[0].data;
            const sitesRes = data[1];
            const loginEventsRes = data[2];

            return {
                users: usersRes,
                sitesToOrganisations: sitesRes,
                loginEvents: loginEventsRes
            };
        } catch (e) {
            console.log(e);
        }
        return null;
    }

    /**
     * This will get the site naming config for a set of sites
     * A map of siteID to SiteConfig object will be returned
     * @param sites: Set<string> - the set of site names to return
     */
    async getSiteNamingConfig(): Promise<Map<string, SiteConfig>> {


        // make a request for each site and then wait on all of them
        const req: Observable<HttpResponse<object>> = this.gridEdgeApiRequestService.makeRequest(DataMarshallerEndPoints.SITE_LIST, new HttpParams());
        const res = await req.toPromise();

        const returnMap: Map<string, SiteConfig> = new Map<string, SiteConfig>();
        if (res.status === 200) {
            const sites: {}[] = res.body as [];
            for (let i = 0; i < sites.length; i++) {
                const siteData: any = sites[i];
                const siteConfig: SiteConfig = SiteConfig.fromConfigJson(siteData);
                returnMap.set(siteConfig.uuid, siteConfig);
            }
        }

        return returnMap;
    }
}

import {LoginEvent} from './LoginEvent';

export enum WHITELABEL_OPERATOR {
    GRIDEDGE = 'gridedge',
    ZEMPOWER = 'zempower'
}

export class Edge2xUser {

    userProfile: UserProfile;
    organisation: string;
    email: string;
    role: UserRoleEnum;
    sites: string[];
    loginEvents: Map<string, LoginEvent>;
    uid: string;
    operator: WHITELABEL_OPERATOR;

    constructor(organisation: string, email: string, role: UserRoleEnum, sites: string[], userProfile: UserProfile, loginEvents: Map<string, LoginEvent>, uid: string, operator: WHITELABEL_OPERATOR) {
        this.userProfile = userProfile;
        this.organisation = organisation;
        this.email = email;
        this.role = role;
        this.sites = sites;
        if (loginEvents == null) {
            this.loginEvents = new Map<string, LoginEvent>();
        } else {
            this.loginEvents = new Map();
            for (const key in loginEvents) {
                const event: {} = loginEvents[key];
                this.loginEvents.set(key, new LoginEvent(event['date'], event['format'], event['time'], event['timestamp']));
            }
        }
        this.uid = uid;
        this.operator = operator;
    }

    /**
     * Checks whether the provided first of last name is valid
     * @param name - the input provided in the request
     */
    static isValidFirsOrLastname(name): boolean {
        if (typeof name !== 'string') {
            console.log(1);
            return false;
        }

        // Check its valid size
        if (name.length <= 0 || name.length > 75) {
            console.log(2);
            return false;
        }

        return true;
    }

    getAsFirebaseJSON() {
        return {
            firstname: this.userProfile.firstname,
            lastname: this.userProfile.lastname,
            email: this.email,
            sites: this.sites,
            organisation: this.organisation,
            role: this.role,
            operator: this.operator,
        };
    }
}


export class UserProfile {
    firstname: string;
    lastname: string;


    constructor(firstname: string, lastname: string) {
        this.firstname = firstname;
        this.lastname = lastname;
    }


    toJson() {
        return {
            firstname: this.firstname,
            lastname: this.lastname
        };
    }
}


export enum UserRoleEnum {
    ADMIN = 'ADMIN',
    BASIC = 'BASIC'
}

<div class="container-fluid authCardholder">
    <app-page-background-image></app-page-background-image>

    <mat-card class="authCard passwordResetBox text-center">
        <div class="container p-0 align-items-center verticalFlex h-100 w-100">
            <app-auth-card-header></app-auth-card-header>
            <div class="d-flex holder justify-content-center flex-1 align-items-center">
                <div *ngIf="authService.isLoggingTheUserIn == true && !htmlErrorMessage">
                    <app-loading-spinner [loadingText]="generalService.LOGGING_USER_IN_LOADING_MESSAGE" [isSmallText]="true"></app-loading-spinner>
                </div>
                <div *ngIf="authService.isLoggingTheUserIn == false && showEmailPromptForm">
                    <form [formGroup]="emailPromptForm" (ngSubmit)="loginWithLink(email.value)"
                          autocomplete="off">

                        <div class="body-2">Please enter the email address that was used to generate this login request</div>

                        <mat-form-field floatLabel=never class="w-100 noPaddingTopFormInput pt-3 pb-2"
                                        appearance="outline">
                            <input
                                id="email" matInput
                                placeholder='Email'
                                formControlName="email">
                            <mat-error id="invalid_email_error" class="error" *ngIf="email.invalid">
                                Please enter a valid email
                            </mat-error>
                        </mat-form-field>
                        <div class="text-center pt-2">
                            <button id="signInButton" mat-raised-button [disabled]="emailPromptForm.invalid" type="submit"
                                    color="primary" class="row col-6 mt-1 mb-1 button">SUBMIT
                            </button>
                        </div>
                    </form>
                </div>


                <div *ngIf="htmlErrorMessage">
                    <div class="body-2" style="white-space: pre-line">{{htmlErrorMessage}}</div>
                    <div class="pt-4 caption" *ngIf="showSendAnotherEmail">Please click <a (click)="pageNavigatorService.loadLoginPage()"  class="cursorPointer hyperlink">here</a> to request another login link
                    </div>
                </div>

            </div>
        </div>
    </mat-card>
</div>

import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Edge2xUser} from '../../../../data/edge2xUser';
import {GeneralService} from '../../../../services/generalService/general.service';

@Component({
  selector: 'app-user-stats-modal',
  templateUrl: './user-stats-modal.component.html',
  styleUrls: ['./user-stats-modal.component.scss']
})
export class UserStatsModalComponent implements OnInit {
  user: Edge2xUser;

  constructor(@Inject(MAT_DIALOG_DATA) data, public generalService: GeneralService, private dialogRef: MatDialogRef<UserStatsModalComponent>) {
    this.user = data.user;
  }

  ngOnInit(): void {
  }

}

import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LoadingService {
    callback;
    isShown: boolean;
    isError: boolean;

    constructor() {
        this.isShown = false;
        this.isError = false;
    }


    /**
     * Shows the loading spinner
     * A cancel function can be passed if you need to cancel observables in the caller
     * Loading text can also be passed
     * @param callbackWhenCancelled: function() - what to do when calling cancel
     *                                            should be passed like so ()=>{ //handle cancelling of your thing}
     *     ********************** POSSIBLE IMPROVEMENT - If this is null no cancel button will be shown
     * @param loadingText: string - the loading text to display
     */
    setLoading(callbackWhenCancelled) {
        this.isShown = true;
        this.isError = false;
        this.callback = callbackWhenCancelled;
    }

    /**
     * Hide the loading spinner and clear variables
     * Call this when your action has completed
     */
    hideLoading() {
        this.isShown = false;
        this.callback = null;
        this.isError = false;
    }

    /**
     * Used to set the loading spinner to error
     * This will change the loading text to the parameter passed in
     * Gary will also stop spinning, turn sad and the background will become opaque
     * @param errorText: string - The error text you want to display
     */
    setErrorLoading() {
        this.isShown = true;
        this.isError = true;
    }

    /**
     * Called when the loading spinner cancel button is clicked
     * if you provided a callback it will be executed
     * variables will be cleared
     */
    cancelLoading() {
        if (this.callback != null) {
            console.log('Calling the cancel callback');
            this.callback();
        }
        this.hideLoading();
    }
}

import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Edge2xUser, UserRoleEnum} from '../../../../data/edge2xUser';

@Component({
  selector: 'app-delete-user-modal',
  templateUrl: './delete-user-modal.component.html',
  styleUrls: ['./delete-user-modal.component.scss']
})
export class DeleteUserModalComponent implements OnInit {


  deleteCheckString = '';
  userToDelete: Edge2xUser;

  constructor(@Inject(MAT_DIALOG_DATA) data, private dialogRef: MatDialogRef<DeleteUserModalComponent>) {
    this.userToDelete = data.user;
  }

  ngOnInit() {
  }

  /**
   * Determines whether the user has correctly written DELETE and 100% wants to delete the user
   */
  canSubmit() {
    return this.deleteCheckString === 'DELETE';
  }

  /**
   * This will submit the dialog and pass the user we want to delete back
   */
  submit() {
    if (this.canSubmit()) {
      this.dialogRef.close(this.userToDelete);
    }
  }

  /**
   * This will close the dialog... null be returned
   */
  close() {
    this.dialogRef.close(null);
  }


}

import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {FIREBASE_FUNCTIONS, FirebaseService} from '../../services/firebase/firebase.service';
import {AuthService} from '../../services/auth/auth.service';
import {RecaptchaComponent} from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import {EMAIL_ACTION_HANDLER_MODES, URL_PATHS} from 'src/app/data/Enums';


@Component({
    selector: 'app-email-action-handler',
    templateUrl: './email-action-handler.component.html',
    styleUrls: ['./email-action-handler.component.scss']
})


export class EmailActionHandlerComponent implements OnInit {

    environment = environment;


    handlerMode: EMAIL_ACTION_HANDLER_MODES;
    oobCode: string;
    isLoading;
    resetPasswordForm: UntypedFormGroup;
    password: UntypedFormControl;
    passwordConfirmation: UntypedFormControl;

    errorMessage: string;
    successMessage: string;

    passwordRegex = /^(?=.*\d)(?=.*[!"$%^£&*()\-_=+\[\]{};:@'~#<>,.\/\\?])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    passwordRules = 'Your password must be at least 8 characters long and needs to contain: 1 number, 1 special character, 1 lowercase character and 1 uppercase character';

    @ViewChild('captchaRef', {static: true}) captchaRef: RecaptchaComponent;

    constructor(private router: Router, private firebaseService: FirebaseService, private activatedRoute: ActivatedRoute,
                public authService: AuthService, private fb: UntypedFormBuilder) {

        // set it to be loading initially
        this.isLoading = true;
        this.activatedRoute.queryParams.subscribe(params => {
            // try get the enum for the mode
            this.handlerMode = this.getActionEnum(params['mode']);
            this.oobCode = params['oobCode'];


            if (this.handlerMode == null || this.oobCode == null) {
                // they have changed the url and it doesnt match a mode enum
                // so redirect to login
                this.router.navigate([URL_PATHS.LOGIN]);
            }

            // turn the loading off
            this.isLoading = false;
        });
    }

    public get EMAIL_ACTION_HANDLER_MODES() {
        return EMAIL_ACTION_HANDLER_MODES;
    }

    ngOnInit() {
        switch (this.handlerMode) {
            case EMAIL_ACTION_HANDLER_MODES.RECOVER_EMAIL:
                this.handleEmailRecovered();
                break;
            case EMAIL_ACTION_HANDLER_MODES.VERIFY_EMAIL:
                this.handleVerifyEmail();
                break;
            case EMAIL_ACTION_HANDLER_MODES.RESET_PASSWORD:
                this.password = new UntypedFormControl('', [Validators.required, Validators.pattern(this.passwordRegex)]);
                this.passwordConfirmation = new UntypedFormControl('', [Validators.required, Validators.pattern(this.passwordRegex)]);

                this.resetPasswordForm = this.fb.group({
                    password: this.password,
                    passwordConfirmation: this.passwordConfirmation
                }, {validators: this.matchInputs('password', 'passwordConfirmation')});
                break;

        }
    }

    callRecaptcha() {
        if (this.resetPasswordForm.valid) {
            if (this.getPassword() === this.getPasswordConfirmation()) {
                // show the loading spinner
                this.isLoading = true;
                this.captchaRef.execute();
            } else {
                alert('Passwords don\'t match');
            }
        }

    }

    recaptchaReposeHandler = async (token) => {
        console.log('THIS IS THE CALLBACK');
        console.log(token);
        console.log(this);
        // if the login form is completed
        const recaptchaFailedError = 'Failed to pass recaptcha test';

        const result = await this.firebaseService.callFirebaseFunction(FIREBASE_FUNCTIONS.CHECK_RECAPTCHA_CALLABLE, {text: token});
        if (result != null && result.statusCode === 200) {
            await this.changePassword(this.getPassword());
        } else {
            this.authService.isLoggingTheUserIn = false;
            this.errorMessage = recaptchaFailedError;
        }
    }



    /**
     * This is what recaptcha will call when a request has been made
     * a token will be provided.
     * We must declare this as a property otherwise we will lose reference to 'this'
     * Call the cloud function with the token to validate it
     * Sign them in if successful otherwise show error.
     * @param token: string - The token that will be supplied by recaptcha
     */
    async recaptchaErrorHandler() {
        this.authService.isLoggingTheUserIn = false;
        this.errorMessage = 'Failed to pass recaptcha test';

    }

    async changePassword(newPassword) {
        try {
            const response = await this.authService.comfirmPasswordReset(this.oobCode, newPassword);
            this.successMessage = 'Password changed successfully';
        } catch (e) {
            console.log(e);
            this.errorMessage = e;
        } finally {
            this.isLoading = false;
        }
    }

    async handleEmailRecovered() {
        // Get the restored email address.
        const info = await this.authService.checkActionCode(this.oobCode);
        const restoredEmail = info['data']['email'];
        await this.handleGenericActionCodeFunction('Email has been recovered. It is now set to ' + restoredEmail);
    }

    async handleVerifyEmail() {
        await this.handleGenericActionCodeFunction('Your email is verified');
    }


    async handleGenericActionCodeFunction(sucessMessage: string) {
        try {
            await this.authService.applyActionCode(this.oobCode);
            this.successMessage = sucessMessage;
        } catch (e) {
            console.log(e);
            this.errorMessage = e;
        } finally {
            this.isLoading = false;
        }
    }


    /**
     * @return the text that has been typed into the password box
     */
    getPassword() {
        return this.resetPasswordForm.get('password').value;
    }

    /**
     * @return the text that has been typed into the password confirmation box
     */
    getPasswordConfirmation() {
        return this.resetPasswordForm.get('passwordConfirmation').value;
    }

    getActionEnum(stringParam: string): EMAIL_ACTION_HANDLER_MODES {
        switch (stringParam) {
            case 'resetPassword':
                return EMAIL_ACTION_HANDLER_MODES.RESET_PASSWORD;
            case 'recoverEmail':
                return EMAIL_ACTION_HANDLER_MODES.RECOVER_EMAIL;
            case 'verifyEmail':
                return EMAIL_ACTION_HANDLER_MODES.VERIFY_EMAIL;
            default:
                return null;
        }
    }

    /**
     * Function added to check if the content of the 2 inputs are the same
     * Function called directly inside the form
     * @param controlName: String - the first control content to match
     * @param matchingControlName: String - the second content to match
     */
    matchInputs(controlName: string, matchingControlName: string) {
        return (formGroup: UntypedFormGroup) => {
            const control = formGroup.controls[controlName];
            const matchingControl = formGroup.controls[matchingControlName];

            if (matchingControl.errors && !matchingControl.errors.NotEqual) {
                // return if another validator has already found an error on the matchingControl
                return;
            }

            // set error on matchingControl if validation fails
            if (control.value !== matchingControl.value) {
                matchingControl.setErrors({NotEqual: true});
            } else {
                matchingControl.setErrors(null);
            }
        };
    }

}

import {BackgroundLoaderService} from '../../services/backgroundLoader/background-loader.service';
import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-page-background-image',
    templateUrl: './page-background-image.component.html',
    styleUrls: ['./page-background-image.component.scss']
})
export class PageBackgroundImageComponent implements OnInit {
    backgroundImagePath: string;

    constructor(public backgroundLoaderService: BackgroundLoaderService) {
    }

    ngOnInit() {
        this.backgroundImagePath = this.backgroundLoaderService.getRandomImage();
    }

}

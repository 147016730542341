<div class="verticalFlex">
    <div  class="flex-1" style="min-height: 0;overflow-y: auto">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-4 col-sm-6 mb-2 py-0 px-1">
                    <div class="card cursorPointer h-100 p-3 position-relative" (click)="addNewOrganisation(null)">
                        <div class="d-flex verticalFlex justify-content-center align-items-center">
                            <mat-icon class="plusIcon">add_circle_outline</mat-icon>
                            <div>Add New User Group</div>
                        </div>
                    </div>
                </div>



                <div *ngFor="let org of organisationsSitesService.allOrganisations" class="col-lg-4 col-sm-6 mb-2 py-0 px-1">
                    <div class="card p-3">
                        <div class="d-flex">
                            <h4>{{org.name}}</h4>
                            <div class="flex-1"></div>
                            <mat-icon (click)="addNewOrganisation(org)" class="mx-2 editIcon">edit</mat-icon>
                        </div>
                        <div>
                            <div style="height: 200px;overflow-y: auto">
                                <mat-chip-list class="removeMargin">
                                    <mat-chip *ngFor="let site of org.sites">{{organisationsSitesService.getSiteDisplayName(site)}}</mat-chip>
                                </mat-chip-list>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  private _DEFAULT_DURATION = 4000;
  private _DEFAULT_ACTION = 'OK';

  constructor(private snackBar: MatSnackBar) {
  }

  /**
   * opens a snack bar
   * @param message: string - the message you want to show
   * @param action: string? - the message in the button (optional)
   * @param duration: number - how long you want the snackbar to show in ms
   */
  openSnackBar(message: string, action?: string, duration?: number) {
    const actionParam = action ? action : this._DEFAULT_ACTION;
    this.snackBar.open(message, actionParam, {

      duration: duration ? duration : this._DEFAULT_DURATION,
      panelClass: 'snackBarMessage'
    });
  }
}

export class LoginEvent {
  date: string;
  format: string;
  time: string;
  timestamp: string;


  constructor(date: string, format: string, time: string, timestamp: string) {
    this.date = date;
    this.format = format;
    this.time = time;
    this.timestamp = timestamp;
  }
}

import {Injectable} from '@angular/core';
import {Edge2xUser, UserProfile, UserRoleEnum} from '../../data/edge2xUser';
import {FIREBASE_FUNCTIONS, FirebaseService} from '../../auth/services/firebase/firebase.service';
import * as _ from 'lodash';
import {BehaviorSubject} from 'rxjs';
import {UserSitesClaimsDataObject} from '../../data/UserSitesClaimsDataObject';


@Injectable({
  providedIn: 'root'
})
export class Edge2xUsersService {


  rawJson: {};
  users: Edge2xUser[] = null;
  users$: BehaviorSubject<Edge2xUser[]> = new BehaviorSubject<Edge2xUser[]>(this.users);

  constructor(private firebaseService: FirebaseService) {
  }

  /**
   * This will set up users if provided json
   * [
   *    {
   *        firstname: "This is the firstname"
   *        lastname: "This is the lastname",
   *        organisation: "org",
   *        role: "ADMIN" OR "BASIC",
   *        sites: ["site1", "site2"]
   *    },
   *    ...
   * }
   * @param userJson: [] - see above for example array
   * @param loginEvents: {} - uid to login events map
   *    {
   *     "uid" : {
   *       "firebase_push_id" : {
   *         "date" : "2022-02-24",
   *         "format" : "2022-02-24T10:47:31Z",
   *         "time" : "10:47:31",
   *         "timestamp" : 1645699651185
   *       }
   *     },
   *     ...
   */
  setUpUsers(userJson, loginEvents) {
    this.rawJson = _.cloneDeep(userJson);
    const newWhiteList = [];
    for (let i = 0; i < userJson.length; i++) {
      const userProfile: UserProfile = new UserProfile(userJson[i]['firstname'], userJson[i]['lastname']);
      let loginEventsForUser = null;
      if (loginEvents != null) {
        loginEventsForUser = loginEvents[userJson[i]['uid']];
      }

      newWhiteList.push(new Edge2xUser(
        userJson[i]['organisation'],
        userJson[i]['email'],
        userJson[i]['role'] as UserRoleEnum,
        userJson[i]['sites'],
        userProfile,
        loginEventsForUser,
        userJson[i]['uid'],
        userJson[i]['operator']
      ));
    }
    this.users = newWhiteList;
  }

  /**
   * This will call the create user cloud function
   * if the user is successfully created, it will add them to the beginning of the list of users
   * @param newUser: Edge2xUser - The user we want to add
   */
  async addNewUser(newUser: Edge2xUser) {
    const newUserJson = newUser.getAsFirebaseJSON();
    const dataParam = {
      user: newUserJson
    };
    const res = await this.firebaseService.callFirebaseFunction(FIREBASE_FUNCTIONS.CREATE_USER_FROM_SUPER_ADMIN, dataParam);

    if (res.statusCode === 200) {
      newUser.uid = res.data.uid;
      this.users.unshift(newUser);
      this.users$.next(this.users);
    } else {
      throw new Error(res.message);
    }
  }


  /**
   * This will call the replace user cloud function
   * if successful it will update the user in the list
   * @param newUser: Edge2xUser - a edge2xuser object reflecting the required changes
   * @param currentUser: Edge2xUser - the original user object which we want to modify
   */
  async replaceUser(newUser: Edge2xUser, currentUser: Edge2xUser) {
    const newUserJson = newUser.getAsFirebaseJSON();
    const dataParam = {
      previousEmail: currentUser.email,
      userData: newUserJson
    };
    const res = await this.firebaseService.callFirebaseFunction(FIREBASE_FUNCTIONS.UPDATE_USER_FROM_SUPER_ADMIN, dataParam);

    if (res.statusCode === 200) {
      for (let i = 0; i < this.users.length; i++) {
        if (this.users[i].email === currentUser.email) {
          this.users[i] = newUser;
          break;
        }
      }
      this.users$.next(this.users);
    } else {
      throw new Error(res.message);
    }
  }

  /**
   * This will call the delete user cloud function
   * if this is successful the user with the matching email will be removed from the user list field
   * @param userToDelete: Edge2xUser - the user we want to delete
   */
  async deleteUser(userToDelete: Edge2xUser) {
    const dataParam = {
      email: userToDelete.email,
    };
    const res = await this.firebaseService.callFirebaseFunction(FIREBASE_FUNCTIONS.DELETE_USER_FROM_SUPER_ADMIN, dataParam);

    if (res.statusCode === 200) {
      let index = null;
      for (let i = 0; i < this.users.length; i++) {
        if (this.users[i].email === userToDelete.email) {
          index = i;
          break;
        }
      }

      if (index != null) {
        this.users.splice(index, 1);
        this.users$.next(this.users);
      }
    } else {
      console.log('The delete user request had the following res: ');
      console.log(res);
      console.log(res.message);
      throw new Error(res.message);
    }
  }
}
